export default class DateTimeService {
    static convertBackDateToInput(unixTimestamp: number) {
        const milliseconds = unixTimestamp * 1000;
        const date = new Date(milliseconds);
        return date.toISOString().slice(0, 16);
    }

    // Из unix timestamp в объект Date
    static convertBackDateToDate(unixTimestamp: number) {
        const milliseconds = unixTimestamp * 1000;
        return new Date(milliseconds);
    }

    // convertDate to unix timestamp
    static convertDateToUnixTimestamp(date: Date) {
        return Math.floor(date.getTime() / 1000);
    }

    // convertDate to 11:11:11
    static convertDateToTime(date: Date) {
        return date.toTimeString().slice(0, 8);
    }

    // из unix timestamp в строку вида "Вт, 11 мая, 12:00"
    static convertBackDateToString(unixTimestamp: number) {
        const milliseconds = unixTimestamp * 1000;
        const localDate = new Date(milliseconds);

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            day: '2-digit',
            month: '2-digit',
            weekday: 'short',
        };

        // @ts-ignore
        const formattedDate = localDate.toLocaleString('ru-RU', options);
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }

    // из unix timestamp в дату вида '23 ноября' без года с русскими названиями месяцев
    static convertUnixTimestampToShortDate(unixTimestamp: number) {
        const date = new Date(unixTimestamp * 1000);
        const day = date.getDate();
        const month = DateTimeService.convertMonthNumberToRussian(date.getMonth());
        return `${day} ${month}`;
    }

    // Получение диапазона из 2 дат вида '23 ноября - 25 декабря' без года с русскими названиями месяцев или '22-23 ноября' без года с русскими названиями месяцев
    static convertUnixTimestampToShortDateRange(unixTimestamp1: number, unixTimestamp2: number) {
        const date1 = new Date(unixTimestamp1 * 1000);
        const date2 = new Date(unixTimestamp2 * 1000);
        const day1 = date1.getDate();
        const month1 = DateTimeService.convertMonthNumberToRussian(date1.getMonth());
        const day2 = date2.getDate();
        const month2 = DateTimeService.convertMonthNumberToRussian(date2.getMonth());
        if (month1 === month2) {
            return `${day1}-${day2} ${month1}`;
        } else {
            return `${day1} ${month1} - ${day2} ${month2}`;
        }
    }


    private static convertMonthNumberToRussian(month1: number) {
        switch (month1) {
            case 0:
                return 'января';
            case 1:
                return 'февраля';
            case 2:
                return 'марта';
            case 3:
                return 'апреля';
            case 4:
                return 'мая';
            case 5:
                return 'июня';
            case 6:
                return 'июля';
            case 7:
                return 'августа';
            case 8:
                return 'сентября';
            case 9:
                return 'октября';
            case 10:
                return 'ноября';
            case 11:
                return 'декабря';
            default:
                return '';
        }
    }

    // unix to short date -> 21 мая
    static unixToShortStringDate(unixTimestamp: number) {
        const date = new Date(unixTimestamp * 1000);
        const day = date.getDate();
        const month = DateTimeService.convertMonthNumberToRussian(date.getMonth());
        return `${day} ${month}`;
    }

    //  date: [number, number, number]; // Массив [год, месяц, день] -> 21 мая
    static convertDateToShortDate(date: number[]) {
        const [, month, day] = date;
        return `${day.toString().padStart(2, '0')} ${DateTimeService.convertMonthNumberToRussian(month)}`;
    }

    // [number, number]; // Массив [часы, минуты] -> 12:00
    static convertTimeToShortTime(time: number[]) {
        const [hours, minutes] = time;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    // [number, number], [number, number] -> 12:00 - 13:00
    static convertTimeRangeToShortTimeRange(time1: number[], time2: number[]) {
        return `${DateTimeService.convertTimeToShortTime(time1)} - ${DateTimeService.convertTimeToShortTime(time2)}`;
    }

    static convertUnixTimestampToYear(startDate: number | undefined) {
        if (startDate === undefined) {
            return '';
        }
        const date = new Date(startDate * 1000);
        return date.getFullYear();
    }

    // date: [number, number, number] -> Time
    static convertBackTimeToDate(time: any) {
        const [hours, minutes] = time;
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
    }
}