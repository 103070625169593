import React, {useContext, useEffect} from 'react';
import cl from './Login.module.css';
import Background from "../../../components/templates/Background/Background";
import {Link, useNavigate} from "react-router-dom";
import {Button, Form, Input} from "antd";
import {AppContext} from "../../../index";
import {observer} from "mobx-react";

const Login = () => {

    const {store} = useContext(AppContext)

    const [form] = Form.useForm();

    const navigate = useNavigate();

    useEffect(() => {
        if (store.isAuth) {
            navigate('/hackathons');
        }
    }, [navigate, store.isAuth])

    return (
        <Background>
            <div className={cl.box}>
                <Form
                    form={form}
                    onFinish={store.users.login}
                    scrollToFirstError
                    layout={"vertical"}
                    className={cl.formBox}>
                    <h3>Вход в аккаунт</h3>
                    <div className={cl.form}>

                        <Form.Item
                            name={"username"}
                            label={"Ник"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста введите ник',
                                },
                                {
                                    min: 3,
                                    message: 'Ник должен быть не менее 3 символов',
                                },
                                {
                                    max: 20,
                                    message: 'Ник должен быть не более 20 символов',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name={"password"}
                            label={"Пароль"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста введите пароль',
                                },
                                {
                                    min: 6,
                                    message: 'Пароль должен быть не менее 6 символов',
                                },
                                {
                                    max: 100,
                                    message: 'Пароль должен быть не более 100 символов',
                                }
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                    </div>
                    <div className={cl.btnBox}>

                        <Form.Item className={cl.btn}>
                            <Button htmlType={"submit"}>Войти</Button>
                        </Form.Item>
                    </div>
                    <div className={cl.linkBox}>
                        <Link to={"/register"} className={cl.link}>Зарегистрироваться</Link>
                        <Link to={"/"} className={cl.link}>Войти через MBC аккаунт</Link>
                    </div>
                </Form>
            </div>
        </Background>
    );
};

export default observer(Login);