import React, {useContext, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import Background from "../../../components/templates/Background/Background";
import cl from "./Register.module.css";
import {AppContext} from "../../../index";
import {Button, Form, Input} from "antd";
import {observer} from "mobx-react";

const Register = () => {

    const {store} = useContext(AppContext)

    const [form] = Form.useForm();

    const navigate = useNavigate();

    useEffect(() => {
        if (store.isAuth) {
            navigate('/hackathons');
        }
    }, [navigate, store.isAuth])

    return (
        <Background>
            <div className={cl.box}>
                <Form form={form}
                      onFinish={store.users.register}
                      scrollToFirstError
                      layout={"vertical"}
                      initialValues={{
                          firstName: "Алсу",
                          lastName: "Гизатуллина",
                          username: "minusd",
                          email: "admin@admin.ru",
                          password: "123123",
                          passwordConfirm: "123123"
                      }}
                      className={cl.formBox}
                >
                    <h3>Регистрация</h3>
                    <div
                        className={cl.form}
                    >
                        <Form.Item
                            name={"firstName"}
                            label={"Имя"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста введите имя',
                                },
                                {
                                    min: 2,
                                    message: 'Имя должно быть не менее 2 символов',
                                },
                                {
                                    max: 50,
                                    message: 'Имя должно быть не более 50 символов',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name={"lastName"}
                            label={"Фамилия"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста введите фамилию',
                                },
                                {
                                    min: 2,
                                    message: 'Фамилия должна быть не менее 2 символов',
                                },
                                {
                                    max: 50,
                                    message: 'Фамилия должна быть не более 50 символов',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name={"username"}
                            label={"Ник"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста введите ник',
                                },
                                {
                                    min: 5,
                                    message: 'Ник должен быть не менее 5 символов',
                                },
                                {
                                    max: 20,
                                    message: 'Ник должен быть не более 20 символов',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name={"email"}
                            label={"Email"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста введите email',
                                },
                                {
                                    type: 'email',
                                    message: 'Неправильный формат email',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name={"password"}
                            label={"Пароль"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста введите пароль',
                                },
                                {
                                    min: 6,
                                    message: 'Пароль должен быть не менее 6 символов',
                                },
                                {
                                    max: 100,
                                    message: 'Пароль должен быть не более 100 символов',
                                }
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item
                            name={"passwordConfirm"}
                            label={"Подтверждение пароля"}
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Подтвердите пароль',
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Пароли не совпадают'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        {/*<input type="text" placeholder="Имя"/>*/}
                        {/*<input type="text" placeholder="Фамилия"/>*/}
                        {/*<input type="text" placeholder="Ник"/>*/}
                        {/*<input type="email" placeholder="Email"/>*/}
                        {/*<input type="password" placeholder="Пароль"/>*/}
                        {/*<input type="password" placeholder="Подтверждение пароля"/>*/}
                    </div>
                    <div className={cl.btnBox}>

                        <Form.Item>
                            <Button htmlType={"submit"} className={cl.btn}>Зарегистрироваться</Button>
                        </Form.Item>

                        {/*<PrimaryButtonHeight buttonText={"Зарегистрироваться"} link={"/email-confirmation"}/>*/}
                    </div>
                    <div className={cl.linkBox}>
                        <Link to={"/login"} className={cl.link}>Уже есть аккаунт?</Link>
                        <Link to={"/"} className={cl.link}>Войти через MBC аккаунт</Link>
                    </div>
                </Form>
            </div>
        </Background>
    );
};

export default observer(Register);