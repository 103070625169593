import React from 'react';
import cl from './TimelineStage.module.css'
import CircleTimeline from "../CircleTimeline/CircleTimeline";

interface TimelineStageProps {
    circleColor: string;
    timelineImage: string;
    h2: string;
    className: string;
    p: string;
}

const TimelineStage: React.FC<TimelineStageProps> = ({ circleColor, timelineImage, h2, className, p }) => {
    return (
        <div className={cl.box}>
            <div className={cl.titleBox}>
                <CircleTimeline circleColor={circleColor} timelineImage={timelineImage} />
                <h2>{h2}</h2>
            </div>
            <div className={cl.descriptionBox}>
                <div className={className} />
                <p>{p}</p>
            </div>
        </div>
    );
};

export default TimelineStage;
