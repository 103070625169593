import React, {useContext, useEffect, useState} from 'react';
import cl from './Team.module.css';
import Header from "../../components/templates/Header/Header";
import IndentBox from "../../components/templates/IndentBox/IndentBox";
import PrimaryButton from "../../components/ui/PrimaryButton/PrimaryButton";
import ava from "../../media/images/ava.jpg";
import ava2 from "../../media/images/ava2.jpg";
import TitleLine from "../../components/TitleLine/TitleLine";
import PartnerInfo from "../../components/PartnerInfo/PartnerInfo";
import Footer from "../../components/templates/Footer/Footer";
import {AppContext} from "../../index";
import {Button, Divider, Form, Input, message, Modal, Tag} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {ITeam} from "../../models/ITeam";
import DateTimeService from "../../service/DateTimeService";
import {EditOutlined} from "@ant-design/icons";

const Team = () => {
    const {id} = useParams<{
        id: string
    }>()
    const {store} = useContext(AppContext)

    const [editTitleForm] = Form.useForm();
    const [editDescriptionForm] = Form.useForm();

    const [team, setTeam] = useState({} as ITeam)
    const [inviteCode, setInviteCode] = useState('')

    const [editNameModal, setEditNameModal] = useState(false)
    const [editDescriptionModal, setEditDescriptionModal] = useState(false)
    const [confirmReadyModal, setConfirmReadyModal] = useState(false)
    const [inviteModal, setInviteModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [memberForDelete, setMemberForDelete] = useState({} as any)
    const navigate = useNavigate();

    useEffect(() => {
        const fetch = async () => {
            const response = await store.teams.getTeam(id as string)
            if (response)
                setTeam(response)

            if (response?.status === "NOT_READY" && response?.captain?.id === store.user?.id) {
                const response2 = await store.teams.getInviteCode(id as string)
                if (response2)
                    setInviteCode(response2?.code)
            }
        }
        fetch();
    }, [
        id,
        store.teams,
        store.user,
        setTeam,
        setInviteCode,
    ]);

    useEffect(() => {
        // Проверка что пользователь участник команды
        if (team?.members?.filter(member => member.id === store.user?.id).length === 0) {
            navigate('/hackathons')
        }
    }, [team, store.user, navigate])

    const updateTeam = async () => {
        setTeam(await store.teams.getTeam(id as string))
    }

    return (
        <>
            <Modal
                title="Подтверждение действия"
                open={deleteModal}
                onOk={async () => {
                    setDeleteModal(false)
                    await store.teams.deleteMember(id as string, memberForDelete.id)
                    await updateTeam()
                }}
                onCancel={() => setConfirmReadyModal(false)}
                okText="Подтвердить"
                cancelText="Отмена"
            >
                {
                    team?.captain?.id === memberForDelete?.id ?
                        <p>Вы уверены что хотите удалить команду?</p>
                        :
                        memberForDelete?.id === store.user?.id ?
                            <p>Вы уверены что хотите покинуть команду?</p>
                            :
                            <>
                                <p>Вы уверены что хотите удалить участника из команды?</p>
                                <p>
                                    Участник: <Tag>{memberForDelete.username}</Tag>
                                </p>
                            </>
                }
            </Modal>
            <Modal
                title="Код для приглашения участника"
                open={inviteModal}
                onCancel={() => setInviteModal(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={
                        async () => {
                            const response = await store.teams.regenerateInviteCode(id as string)
                            if (response)
                                setInviteCode(response?.code)
                        }
                    }>
                        Перегенерировать
                    </Button>,
                    <Button key="back" onClick={() => setInviteModal(false)}>
                        Закрыть
                    </Button>,
                ]}
            >
                <p>
                    <Divider/>
                    <code>{inviteCode}</code></p>
            </Modal>


            <Modal
                title="Подтвердить отправку команды на проверку"
                open={confirmReadyModal}
                onOk={async () => {
                    setConfirmReadyModal(false)
                    if (await store.teams.readyToCheck(id as string)) {
                        message.success("Команда успешно отправлена на проверку")
                    }
                    await updateTeam()
                }}
                onCancel={() => setConfirmReadyModal(false)}
                okText="Подтвердить"
                cancelText="Отмена"
            >
                <p>Вы уверены что хотите отправить команду на проверку?</p>
                <p>
                    <b>Внимание!</b> После отправки команды на проверку вы не сможете вносить изменения в состав и
                    описание команды.
                </p>
            </Modal>
            <Modal
                open={editNameModal}
                title="Изменить название команды"
                okText="Изменить"
                cancelText="Отмена"
                onCancel={() => {
                    setEditNameModal(false)
                    editTitleForm.resetFields()
                }}
                onOk={() => {
                    editTitleForm
                        .validateFields()
                        .then(async (values) => {
                            editTitleForm.resetFields();
                            setEditNameModal(false)
                            setTeam(await store.teams.editTitle(values, id))
                        })
                }}
            >
                <Form
                    form={editTitleForm}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        title: team?.title
                    }}
                >
                    <Form.Item
                        name="title"
                        label="Название команды"
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста введите название команды',
                            },
                            {
                                min: 5,
                                message: 'Название должно быть не менее 5 символов',
                            },
                            {
                                max: 255,
                                message: 'Название должно быть не более 255 символов',
                            }
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                open={editDescriptionModal}
                title="Изменить описание команды"
                okText="Изменить"
                cancelText="Отмена"
                onCancel={() => {
                    setEditDescriptionModal(false)
                    editDescriptionForm.resetFields()
                }}

                onOk={() => {
                    editDescriptionForm
                        .validateFields()
                        .then(async (values) => {
                            editDescriptionForm.resetFields();
                            setEditDescriptionModal(false)
                            setTeam(await store.teams.editDescription(values, id))
                        })
                }}
            >
                <Form
                    form={editDescriptionForm}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        description: team?.description
                    }}
                >
                    <Form.Item
                        name="description"
                        label="Описание команды"
                        rules={[
                            {
                                max: 255,
                                message: 'Название должно быть не более 255 символов',
                            }
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>

            <Header/>
            <IndentBox>
                <div className={cl.box}>
                    <div className={cl.leftBox}>
                        <div className={cl.hackBox}>
                            <span className={cl.boxTitle}>Хакатон</span>
                            <div className={cl.textBox}>
                                <p className={cl.title}>
                                    {team?.hackathon?.title}
                                </p>
                                <p className={cl.type}>Онлайн</p>
                                <div className={cl.descBox}>
                                    <p className={cl.descTitle}>Регистрация:</p>
                                    <p className={cl.descText}>до {DateTimeService.convertUnixTimestampToShortDate(team?.hackathon?.endApplicationsDate)}
                                    </p>
                                </div>
                                <div className={cl.descBox}>
                                    <p className={cl.descTitle}>Проведение:</p>
                                    <p className={cl.descText}>
                                        {DateTimeService.convertUnixTimestampToShortDateRange(team?.hackathon?.startDate, team?.hackathon?.endDate)}
                                    </p>
                                </div>
                            </div>
                            <div className={cl.btnBox}>
                                <PrimaryButton
                                    text={"Перейти к заданию"}
                                    link={`/hackathons/${team?.hackathon?.id}/task`}/>
                            </div>
                        </div>
                        {
                            team?.status === "NOT_READY" && team?.captain?.id === store.user?.id &&
                            <>
                                <Button
                                    onClick={() => setInviteModal(true)}
                                    className={cl.btnInvite}>
                                    Пригласить участника
                                </Button>
                                <Button className={cl.btnReady}
                                        onClick={() => setConfirmReadyModal(true)}
                                >
                                    Отправить на проверку
                                </Button>
                                <Button className={cl.btnDelete}
                                        onClick={
                                            async () => {
                                                setMemberForDelete(team?.captain)
                                                setDeleteModal(true)
                                            }
                                        }
                                >
                                    Удалить команду
                                </Button>
                            </>
                        }
                        {
                            team?.status === "NOT_READY" && team?.captain?.id !== store.user?.id &&
                            <Button className={cl.btnDelete}
                                    onClick={
                                        async () => {
                                            setMemberForDelete(store.user)
                                            setDeleteModal(true)
                                        }
                                    }
                            >
                                Выйти из команды
                            </Button>
                            // <div className={cl.btnBox}>
                            //     <PrimaryButton
                            //         text={"Выйти из команды"}
                            //         onClick={async () => {
                            //             if (await store.teams.leaveTeam(id as string)) {
                            //                 message.success("Вы успешно вышли из команды")
                            //                 setTeam(await store.teams.getTeam(id as string))
                            //             }
                            //         }}
                            //     />
                            // </div>
                        }
                    </div>
                    <div className={cl.rightBox}>
                        <div className={cl.hackBox}>
                            <span className={cl.boxTitle}>Команда</span>
                            <div className={cl.textBox}>
                                <div className={cl.editBox}>

                                    <div className={cl.descBox}>
                                        <p className={cl.descTitle}>Название:</p>
                                        <p className={cl.descText}>{team?.title}</p>
                                    </div>
                                    {
                                        team?.status === "NOT_READY" &&
                                        <EditOutlined onClick={() => setEditNameModal(true)}/>
                                    }
                                </div>
                                <div className={cl.descBox}>
                                    <p className={cl.descTitle}>Капитан команды:</p>
                                    <p className={cl.descText}>{team?.captain?.username}</p>
                                </div>
                                <div className={cl.editBox}>
                                    <div className={cl.descText}>
                                        <span className={cl.descTitle}>Описание&nbsp;команды: </span>
                                        {team?.description}
                                    </div>
                                    {
                                        team?.status === "NOT_READY" &&
                                        <EditOutlined onClick={() => setEditDescriptionModal(true)}/>
                                    }
                                </div>
                                <div className={cl.descBox}>
                                    <p className={cl.descTitle}>Статус:</p>
                                    <p className={cl.descText}>
                                        {
                                            team?.status === "NOT_READY" &&
                                            <Tag color={"gray"}>Не готова</Tag>
                                        }
                                        {
                                            team?.status === "PARTICIPATING" &&
                                            <Tag color={"green"}>Участвует</Tag>
                                        }
                                        {
                                            team?.status === "READY_TO_CHECK" &&
                                            <Tag color={"blue"}>На проверке</Tag>
                                        }
                                        {
                                            team?.status === "DISQUALIFIED" &&
                                            <Tag color={"red"}>Дисквалифицирована</Tag>
                                        }
                                    </p>
                                </div>
                            </div>
                            <TitleLine title={"Участники"}/>
                            <div className={cl.teamBox}>


                                <PartnerInfo
                                    avatar={ava}
                                    nikName={team?.captain?.username}
                                    name={team?.captain?.firstName}
                                    isCaptain={true}
                                    seeCaptain={team?.captain?.id === store.user?.id}
                                    description={"Мое вдохновение в программировании и опыт веб-разработки делают меня ключевым фронтенд-разработчиком в нашей команде. Мы сосредотачиваем усилия на создании инновационного приложения для оптимизации рабочих процессов в области здравоохранения. "}/>

                                {
                                    team?.members?.filter(member => member.id !== team?.captain?.id)?.map(member =>
                                        <PartnerInfo
                                            avatar={ava2}
                                            nikName={member.username}
                                            name={member.firstName}
                                            del={() => {
                                                setMemberForDelete(member)
                                                setDeleteModal(true)
                                            }}
                                            seeCaptain={team?.captain?.id === store.user?.id && team.status === "NOT_READY"}
                                            description={"Мое вдохновение в программировании и опыт веб-разработки делают меня ключевым фронтенд-разработчиком в нашей команде. Мы сосредотачиваем усилия на создании инновационного приложения для оптимизации рабочих процессов в области здравоохранения. "}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </IndentBox>
            <Footer/>
        </>
    );
};

export default Team;