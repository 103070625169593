import React from 'react';
import cl from './PrimaryButtonHeight.module.css';
import {Link} from "react-router-dom";

interface PrimaryButtonProps {
    buttonText: string;
    link: string; // Добавляем пропс для URL
}

const PrimaryButtonHeight: React.FC<PrimaryButtonProps> = ({ buttonText, link }) => {
    return (
        <Link to={link} className={cl.btn}>
            {buttonText}
        </Link>
    );
};

export default PrimaryButtonHeight;
