import React from 'react';
import cl from './InviteCard.module.css';

import lightning from '../../media/images/lightning.png';
import PrimaryButton from '../ui/PrimaryButton/PrimaryButton';

interface InviteCardProps {
    title: string;
    description: string;
    link: string;
}

const InviteCard: React.FC<InviteCardProps> = ({ title, description, link }) => {
    return (
        <div className={cl.glass}>
            <div className={cl.titleBox}>
                <img src={lightning} alt="lightning" />
                <p className={cl.title}>{title}</p>
            </div>
            <p className={cl.description}>{description}</p>
            <PrimaryButton text="Подробнее" link={link} />
        </div>
    );
};

export default InviteCard;
