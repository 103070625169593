import React from 'react';
import cl from './EmailConfirmation.module.css';
import Background from "../../../components/templates/Background/Background";

const EmailConfirmation = () => {
    return (
        <Background>
            <div className={cl.box}>
                <div className={cl.formBox}>
                    <h2>Подтверждение почты</h2>
                    <p>Пожалуйста, проверьте свою электронную почту и выполните процедуру подтверждения, чтобы
                        активировать вашу учетную запись и начать использовать наши сервисы. Благодарим вас за
                        регистрацию!</p>
                </div>
            </div>
        </Background>
    );
};

export default EmailConfirmation;