import React, {useContext} from 'react';
import cl from './Hackathons.module.css';
import Header from "../../components/templates/Header/Header";

import back from '../../media/images/backHackathons.png';
import hackBack from '../../media/images/hackBack.png';
import hackBack2 from '../../media/images/hackBack2.png';
import hackBack3 from '../../media/images/hackBack3.png';
import hackBack4 from '../../media/images/hackBack4.png';
import hackBack5 from '../../media/images/hackBack5.png';
import hackBack6 from '../../media/images/hackBack6.png';
import TitleLine from "../../components/TitleLine/TitleLine";
import CardHackathon from "../../components/CardHackathon/CardHackathon";
import Footer from "../../components/templates/Footer/Footer";
import IndentBox from "../../components/templates/IndentBox/IndentBox";
import CardsBox from "../../components/templates/CardsBox/CardsBox";
import {AppContext} from "../../index";
import {IHackathon} from "../../models/IHackathon";
import DateTimeService from "../../service/DateTimeService";

const Hackathons = () => {
    const {store} = useContext(AppContext)
    const [hackathons, setHackathons] = React.useState<[IHackathon]>()

    React.useEffect(() => {
        store.hackathons.getAll().then((data) => {
            if (data) {
                setHackathons(data as [IHackathon])
            }
        })
    }, [])

    return (
        <>
            <img src={back} alt={"back"} className={cl.backImg}/>
            <Header/>
            <div className={cl.titleBox}>
                <h1>Хакатоны, квизы, тесты, олимпиады по программированию и разработке</h1>
            </div>
            <IndentBox>
                <h2 className={cl.title} id="programming">Календарь событий</h2>
                <TitleLine title={"Программирование"}/>
                <CardsBox>
                    {
                        hackathons?.map((hackathon) => {
                            return (
                                <CardHackathon
                                    img={hackBack}
                                    title={hackathon.title}
                                    type={'Онлайн'}
                                    data={"до: " + DateTimeService.unixToShortStringDate(hackathon.endDate)}
                                    to={`/hackathons/${hackathon?.id}`}/>
                            )
                        })
                    }
                    {/*<CardHackathon*/}
                    {/*    img={hackBack}*/}
                    {/*    title={'Хакатон по алгоритмическим задачам “Algoritmic Showdown" '}*/}
                    {/*    type={"Онлайн"}*/}
                    {/*    data={"до 20 ноября"}*/}
                    {/*    to={'/hackathon'}/>*/}

                    {/*<CardHackathon*/}
                    {/*    img={hackBack2}*/}
                    {/*    title={'Хакатон CodeSprint: Быстрый старт в программировании '}*/}
                    {/*    type={"Онлайн"}*/}
                    {/*    data={"до 20 ноября"}*/}
                    {/*    to={'/hackathon'}/>*/}

                    {/*<CardHackathon*/}
                    {/*    img={hackBack3}*/}
                    {/*    title={'Хакатон CodeCraft: Реальный мир в виртуальных данных '}*/}
                    {/*    type={"Онлайн"}*/}
                    {/*    data={"до 20 ноября"}*/}
                    {/*    to={'/hackathon'}/>*/}

                    {/*<CardHackathon*/}
                    {/*    img={hackBack}*/}
                    {/*    title={'Хакатон по алгоритмическим задачам “Algoritmic Showdown" '}*/}
                    {/*    type={"Онлайн"}*/}
                    {/*    data={"до 20 ноября"}*/}
                    {/*    to={'/hackathon'}/>*/}

                    {/*<CardHackathon*/}
                    {/*    img={hackBack2}*/}
                    {/*    title={'Хакатон CodeSprint: Быстрый старт в программировании '}*/}
                    {/*    type={"Онлайн"}*/}
                    {/*    data={"до 20 ноября"}*/}
                    {/*    to={'/hackathon'}/>*/}

                    {/*<CardHackathon*/}
                    {/*    img={hackBack3}*/}
                    {/*    title={'Хакатон CodeCraft: Реальный мир в виртуальных данных '}*/}
                    {/*    type={"Онлайн"}*/}
                    {/*    data={"до 20 ноября"}*/}
                    {/*    to={'/hackathon'}/>*/}
                </CardsBox>

                {/*<div id={"creation"}>*/}
                {/*    <TitleLine title={"Дизайн"}/>*/}
                {/*    <CardsBox>*/}
                {/*        <CardHackathon*/}
                {/*            img={hackBack4}*/}
                {/*            title={'Хакатон по веб-дизайну WebCraft '}*/}
                {/*            type={"Онлайн"}*/}
                {/*            data={"до 20 ноября"}*/}
                {/*            to={'/hackathon'}/>*/}

                {/*        <CardHackathon*/}
                {/*            img={hackBack5}*/}
                {/*            title={'Хакатон по графическому дизайну DesignFusion'}*/}
                {/*            type={"Онлайн"}*/}
                {/*            data={"до 20 ноября"}*/}
                {/*            to={'/hackathon'}/>*/}

                {/*        <CardHackathon*/}
                {/*            img={hackBack6}*/}
                {/*            title={'PixelPalooza: Графический хакатон '}*/}
                {/*            type={"Онлайн"}*/}
                {/*            data={"до 20 ноября"}*/}
                {/*            to={'/hackathon'}/>*/}

                {/*        <CardHackathon*/}
                {/*            img={hackBack4}*/}
                {/*            title={'Хакатон по веб-дизайну WebCraft '}*/}
                {/*            type={"Онлайн"}*/}
                {/*            data={"до 20 ноября"}*/}
                {/*            to={'/hackathon'}/>*/}

                {/*        <CardHackathon*/}
                {/*            img={hackBack5}*/}
                {/*            title={'Хакатон по графическому дизайну DesignFusion'}*/}
                {/*            type={"Онлайн"}*/}
                {/*            data={"до 20 ноября"}*/}
                {/*            to={'/hackathon'}/>*/}
                {/*    </CardsBox>*/}
                {/*</div>*/}

                {/*<div id={"general"}>*/}
                {/*    <TitleLine title={"Общие знания"}/>*/}
                {/*    <CardsBox>*/}
                {/*        <CardHackathon*/}
                {/*            img={hackBack4}*/}
                {/*            title={'Хакатон по веб-дизайну WebCraft '}*/}
                {/*            type={"Онлайн"}*/}
                {/*            data={"до 20 ноября"}*/}
                {/*            to={'/hackathon'}/>*/}

                {/*        <CardHackathon*/}
                {/*            img={hackBack5}*/}
                {/*            title={'Хакатон по графическому дизайну DesignFusion'}*/}
                {/*            type={"Онлайн"}*/}
                {/*            data={"до 20 ноября"}*/}
                {/*            to={'/hackathon'}/>*/}

                {/*        <CardHackathon*/}
                {/*            img={hackBack6}*/}
                {/*            title={'PixelPalooza: Графический хакатон '}*/}
                {/*            type={"Онлайн"}*/}
                {/*            data={"до 20 ноября"}*/}
                {/*            to={'/hackathon'}/>*/}

                {/*        <CardHackathon*/}
                {/*            img={hackBack4}*/}
                {/*            title={'Хакатон по веб-дизайну WebCraft '}*/}
                {/*            type={"Онлайн"}*/}
                {/*            data={"до 20 ноября"}*/}
                {/*            to={'/hackathon'}/>*/}

                {/*        <CardHackathon*/}
                {/*            img={hackBack5}*/}
                {/*            title={'Хакатон по графическому дизайну DesignFusion'}*/}
                {/*            type={"Онлайн"}*/}
                {/*            data={"до 20 ноября"}*/}
                {/*            to={'/hackathon'}/>*/}
                {/*    </CardsBox>*/}
                {/*</div>*/}
            </IndentBox>
            <Footer/>
        </>
    )
        ;
};

export default Hackathons;