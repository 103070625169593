import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import Store from "./store/Store";
import "../src/fonts/Floripa.ttf";
import {ConfigProvider, theme} from "antd";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const store = new Store();

export const AppContext = createContext({
    store,
})

root.render(
    <Router>
        <AppContext.Provider value={{store}}>
            <ConfigProvider theme={{
                algorithm: theme.darkAlgorithm,
                token: {
                    fontFamily: 'Nunito',
                }
            }}
            >
                <App/>
            </ConfigProvider>
        </AppContext.Provider>
    </Router>
);

