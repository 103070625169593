import React from 'react';
import cl from './Footer.module.css';

import logo from "../../../media/images/FooterLogo.png";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className={cl.footerBox}>
            <div className={cl.box}>
                <div className={cl.logoBox}>
                    <img src={logo} alt={"logo"}/>
                    <p>© MBC Studio</p>
                    <p>Все права защищены. 2019 – 2023</p>
                </div>
                <div className={cl.linkBox}>
                    <div className={cl.linkBox_1}>
                        <h4>Страницы</h4>
                        <Link to={"/"} className={cl.link}>Главная</Link>
                        <Link to={"/hackathons"} className={cl.link}>Хакатоны</Link>
                        <Link to={"/"} className={cl.link}>Партнерство</Link>
                        <Link to={"/"} className={cl.link}>Победители</Link>
                    </div>
                    <div className={cl.linkBox_1}>
                        <h4>Компания</h4>
                        <Link to={"/"} className={cl.link} target="_blank">MBC Diary</Link>
                        <Link to={"/"} className={cl.link} target="_blank">MBC Recipes</Link>
                        <Link to={"/"} className={cl.link} target="_blank">Telegram Bot</Link>
                        <Link to={"/"} className={cl.link} target="_blank">Команда</Link>
                    </div>
                    <div className={cl.linkBox_1}>
                        <h4>Документы</h4>
                        <Link to={"/"} className={cl.link} target="_blank">Пользовательское соглашение</Link>
                        <Link to={"/"} className={cl.link} target="_blank">Политика конфиденциальности</Link>
                        <Link to={"/"} className={cl.link} target="_blank">Согласие на обработку данных</Link>
                        <Link to={"/"} className={cl.link} target="_blank">Документация</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;