import React from 'react';
import cl from './PrimaryButton.module.css';
import {Link} from "react-router-dom";

interface PrimaryButtonProps {
    text: string;
    link: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ text, link }) => {
    return (
        <Link to={link} className={cl.btn}>
            {text}
        </Link>
    );
};

export default PrimaryButton;
