import React, {useContext, useEffect, useState} from 'react';
import cl from './Hackathon.module.css';
import Header from "../../components/templates/Header/Header";
import ButtonHackathon from "../../components/ui/ButtonHackathon/ButtonHackathon";
import PrimaryButtonHeight from "../../components/ui/PrimaryButtonHeight/PrimaryButtonHeight";
import DeadlineBox from "../../components/ui/DeadlineBox/DeadlineBox";
import HackathonProgram from "../../components/HackathonProgram/HackathonProgram";
import Footer from "../../components/templates/Footer/Footer";
import {AppContext} from "../../index";
import {IHackathon} from "../../models/IHackathon";
import DateTimeService from "../../service/DateTimeService";
import {Link, useParams} from "react-router-dom";
import {Button} from "antd";

const Hackathon = () => {
    const {id} = useParams<{ id: string }>()
    const {store} = useContext(AppContext)

    const [hackathon, setHackathon] = useState({} as IHackathon)

    useEffect(() => {
        const fetch = async () => {
            const response = await store.hackathons.getHackathon(id as string)
            if (response) setHackathon(response)
        }
        fetch();
    }, [store.hackathons, setHackathon])

    return (
        <>
            <div className={cl.preview}>
                <Header/>
                <div className={cl.previewText}>
                    <div className={cl.year}>
                        {
                            DateTimeService.convertUnixTimestampToYear(hackathon?.startDate)
                        }
                    </div>
                    <h1>
                        {hackathon?.title}
                    </h1>
                    {/*<h1>Хакатон по алгоритмическим задачам “Algorithmic Showdown" </h1>*/}
                    <p>Регистрируйся и докажи, что именно ты способен разгадать даже самые непростые задачи!</p>
                    <p className={cl.data}>
                        {DateTimeService.convertUnixTimestampToShortDateRange(
                            hackathon?.startDate,
                            hackathon?.endDate
                        )} | Онлайн формат </p>
                    <ButtonHackathon buttonText={"Принять участие"} link={`/create-team/${hackathon?.id}`}/>
                    <span className={cl.backCircle}/>
                    <span className={cl.backCircle2}/>
                    <span className={cl.backCircle3}/>
                    <span className={cl.backCircle4}/>
                </div>
            </div>
            <div className={cl.box}>
                <div className={cl.textBox}>
                    <div className={cl.titleBox}>
                        <div className={cl.circle}/>
                        <h2>Какая задача?</h2>
                    </div>
                    <p>
                        {hackathon?.shortTask}
                    </p>
                </div>

                <div className={cl.countBox}>
                    <p>В команде могут участвовать до {hackathon?.maxTeamSize} человек</p>
                </div>

                <div className={cl.teamBox}>
                    <h2>Нет команды?</h2>
                    <div className={cl.createTeamBox}>
                        <p>Создай свою команду и докажи, что вы лучшие в своем деле!</p>
                        <PrimaryButtonHeight buttonText={"Создать команду"} link={`/create-team/${hackathon?.id}`}/>
                    </div>
                </div>

                <div className={cl.textBox}>
                    <div className={cl.titleBox}>
                        <div className={cl.circle}/>
                        <h2>Дедлайн</h2>
                    </div>
                    <div className={cl.deadlinesBox}>
                        <DeadlineBox
                            data={DateTimeService.convertUnixTimestampToShortDate(hackathon?.endApplicationsDate)}
                            text={"Окончание приема заявок"}
                        />
                        <DeadlineBox
                            data={DateTimeService.convertUnixTimestampToShortDateRange(hackathon?.startDate, hackathon?.endDate)}
                            text={"Проведение хакатона"}/>
                    </div>
                </div>

                <div className={cl.textBox}>
                    <div className={cl.titleBox}>
                        <div className={cl.circle}/>
                        <h2>Программа хакатона</h2>
                    </div>
                    {
                        hackathon?.steps?.map((program, index) =>
                            <HackathonProgram key={index}
                                              date={DateTimeService.convertDateToShortDate(program.date)}>
                                {
                                    program?.events?.map((event, index) =>
                                        <div key={index}>
                                            {
                                                <div>{DateTimeService.convertTimeRangeToShortTimeRange(event?.startTime, event?.endTime)} - {event.title}</div>
                                            }
                                        </div>
                                    )
                                }
                            </HackathonProgram>
                        )
                    }
                    {/*<HackathonProgram date={"22 ноября"}>*/}
                    {/*    <div>18:00 - 18:30 - Регистрация участников. Торжественное открытие хакатона</div>*/}
                    {/*    <div>18:30 - 19:00 - Объявление темы хакатона</div>*/}
                    {/*</HackathonProgram>*/}

                    {/*<HackathonProgram date={"23 ноября"}>*/}
                    {/*    <div>08:00 - 09:30 - Регистрация участников</div>*/}
                    {/*    <div>10:30 - 13:00 - Работа над проектами</div>*/}
                    {/*    <div>13:30 - 14:00 - Перерыв</div>*/}
                    {/*    <div>14:00 - 17:00 - Работа над проектами</div>*/}
                    {/*    <div>17:00 - 18:00 - Перерыв</div>*/}
                    {/*    <div>18:30 - 22:00 - Работа над проектами</div>*/}
                    {/*</HackathonProgram>*/}

                    {/*<HackathonProgram date={"24 ноября"}>*/}
                    {/*    <div>08:00 - 09:30 - Регистрация участников</div>*/}
                    {/*    <div>09:30 - 12:30 - Доработка проектов</div>*/}
                    {/*    <div>14:00 - 18:00 - Защита проектов команд</div>*/}
                    {/*    <div>18:30 - 19:00 - Совещание жюри</div>*/}
                    {/*    <div>19:00 - 19:30 - Награждение победителей и участников</div>*/}
                    {/*</HackathonProgram>*/}
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Hackathon;