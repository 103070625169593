import React, {useContext, useEffect, useState} from 'react';
import cl from './AdminHackathonsTables.module.css';
import IndentBox from "../../../components/templates/IndentBox/IndentBox";
import Footer from "../../../components/templates/Footer/Footer";
import HeaderAdmin from "../../../components/templates/HeaderAdmin/HeaderAdmin";
import {AppContext} from "../../../index";
import {observer} from "mobx-react";
import {IHackathon} from "../../../models/IHackathon";
import {Button, Modal, Table, Tag, Tooltip} from "antd";
import DateTimeService from "../../../service/DateTimeService";
import {DeleteOutlined, EditOutlined, EyeOutlined, TeamOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const AdminHackathonsTables = () => {
    const {store} = useContext(AppContext)
    const [hackathons, setHackathons] = useState<[IHackathon]>()
    const [selectedHackathon, setSelectedHackathon] = useState<IHackathon>({} as IHackathon)
    const [deleteHackathonModal, setDeleteHackathonModal] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const response = await store.hackathons.getAll();
            setHackathons(response as [IHackathon])
        }
        fetchData()
    }, [
        store.hackathons,
        setHackathons
    ]);

    // Обновление данных
    const updateData = async () => {
        const response = await store.hackathons.getAll();
        setHackathons(response as [IHackathon])
    }


    return (
        <>
            <Modal
                title="Удаление хакатона"
                open={deleteHackathonModal}
                // icon={<ExclamationCircleFille />}
                okText={'Удалить'}
                cancelText={'Отмена'}
                cancelButtonProps={{type: 'default'}}
                onOk={() => {
                    store.hackathons.delete(selectedHackathon.id)
                    updateData();
                }}
                onCancel={() => {
                    setDeleteHackathonModal(false)
                }}
            >
                <p>Вы действительно хотите удалить хакатон <Tag>{selectedHackathon.title}</Tag>?</p>
                <p>Это повлечёт за собой удаление всех связанных данных</p>
            </Modal>

            <HeaderAdmin/>
            <IndentBox>
                <div className={cl.container}>
                    <div className={cl.containerHeader}>
                        <h2>Хакатоны</h2>
                        <Link to={'/admin/hackathons/create'}>
                            <Button type="primary">
                                Создать
                            </Button>
                        </Link>
                    </div>
                    <div className=''>
                        <Table
                            dataSource={hackathons}
                            rowKey={hackathon => hackathon.id}
                            pagination={{position: ['none']}}
                            columns={[
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    render: (id: string) => (
                                        <Tooltip title={<div className={'break-keep'}>{id}</div>}>
                                            <Tag>
                                                {id.slice(0, 5)}
                                            </Tag>
                                        </Tooltip>
                                    )
                                },
                                {
                                    title: 'Название',
                                    dataIndex: 'title',
                                    key: 'title',
                                },
                                {
                                    title: 'Дата начала',
                                    dataIndex: 'startDate',
                                    key: 'startDate',
                                    render: (startDate) => (
                                        <div>
                                            {DateTimeService.convertBackDateToString(startDate)}
                                        </div>
                                    )
                                },
                                {
                                    title: 'Начало регистрации',
                                    dataIndex: 'startApplicationsDate',
                                    key: 'startApplicationsDate',
                                    render: (startApplicationsDate) => (
                                        <div>
                                            {DateTimeService.convertBackDateToString(startApplicationsDate)}
                                        </div>
                                    )
                                },
                                {
                                    title: 'Конец регистрации',
                                    dataIndex: 'endApplicationsDate',
                                    key: 'endApplicationsDate',
                                    render: (endApplicationsDate) => (
                                        <div>
                                            {DateTimeService.convertBackDateToString(endApplicationsDate)}
                                        </div>
                                    )
                                },
                                {
                                    title: 'Действия',
                                    dataIndex: 'id',
                                    key: 'id',
                                    render: (id: string) => (
                                        <div className={cl.activeBox}>
                                            <Link to={`/admin/hackathons/${id}/teams`}>
                                                <TeamOutlined/>
                                            </Link>
                                            <Link to={`/hackathons/${id}`}>
                                                <EyeOutlined/>
                                            </Link>
                                            <Link to={`/admin/hackathons/${id}`}>
                                                <EditOutlined/>
                                            </Link>
                                            <DeleteOutlined onClick={
                                                () => {
                                                    setSelectedHackathon(hackathons?.find(h => h.id === id) as IHackathon)
                                                    setDeleteHackathonModal(true)
                                                }
                                            }/>
                                        </div>
                                    )
                                },
                            ]}
                        />
                    </div>
                </div>
            </IndentBox>
            <Footer/>
        </>
    );
};

export default observer(AdminHackathonsTables);