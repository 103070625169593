import React from 'react';
import cl from './IndentBox.module.css';

interface IndentBoxProps {
    children: React.ReactNode;
}

const IndentBox:React.FC<IndentBoxProps> = ({children}) => {
    return (
        <div className={cl.box}>
            {children}
        </div>
    );
};

export default IndentBox;