import React, {ReactNode} from 'react';
import cl from './Background.module.css'

import logo from '../../../media/images/logo.png';
import backImg from '../../../media/images/regBackImg.png';
import backImg2 from '../../../media/images/regBackImg2.png';
import backImg3 from '../../../media/images/backImg2.png';
import backImg4 from '../../../media/images/backImg6.png';
import backImg5 from '../../../media/images/backImg11.png';

interface BackgroundProps {
    children: ReactNode;
}

const Background: React.FC<BackgroundProps> = ({ children }) => {
    return (
        <div className={cl.back}>
            <div className={cl.logoBox}>
                <img src={logo} alt={'logo'} className={cl.logo}/>
                <p className={cl.logoTitle}>MBC Hackathons</p>
            </div>
            <img src={backImg} alt={'backImg'} className={cl.backImg}/>
            <img src={backImg2} alt={'backImg'} className={cl.backImg2}/>
            <img src={backImg3} alt={'backImg'} className={cl.backImg3}/>
            <img src={backImg3} alt={'backImg'} className={cl.backImg4}/>
            <img src={backImg4} alt={'backImg'} className={cl.backImg5}/>
            <img src={backImg5} alt={'backImg'} className={cl.backImg6}/>
            {children}
        </div>
    );
};

export default Background;