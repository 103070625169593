import {makeAutoObservable} from 'mobx';
import Store from "./Store";
import $api from "../http";
import {IHackathon} from "../models/IHackathon";

export default class HackathonStore {
    private rootStore: Store;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    // hackathons = [];

    getHackathon = async (id: string) => {
        try {
            const response = await $api.get<IHackathon>(`/hackathons/${id}`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    getAll = async () => {
        try {
            const response = await $api.get<IHackathon[]>('/hackathons');
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
        return [];
    }
    delete = async (id: string) => {
        try {
            const response = await $api.delete<IHackathon>(`/hackathons/${id}`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }
    getByIdForAdmin = async (id: string) => {
        try {
            const response = await $api.get<IHackathon>(`/hackathons/${id}/admin`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async create(param: {
        fullTask: any;
        endDate: any;
        description: any;
        endApplicationsDate: any;
        title: any;
        maxScore: any;
        steps: any;
        startApplicationsDate: any;
        startDate: any;
        endSolvingDate: any;
        shortTask: any;
        maxTeamSize: any
    }) {
        try {
            const response = await $api.post<IHackathon>('/hackathons', param);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async update(id: string, data: {
        fullTask: any;
        endDate: number;
        endSolvingDate: number;
        description: any;
        endApplicationsDate: number;
        title: any;
        maxScore: any;
        steps: any;
        startApplicationsDate: number;
        startDate: number;
        shortTask: any;
        maxTeamSize: any
    }) {
        try {
            const response = await $api.put<IHackathon>(`/hackathons/${id}`, data);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async getTask(hackId: string) {
        try {
            const response = await $api.get<any>(`/hackathons/${hackId}/task`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
        return null;
    }

    async sendTask(hackId: string, values: any) {
        try {
            const response = await $api.post<any>(`/hackathons/${hackId}/task`, values);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }

    }
}
