import {makeAutoObservable} from 'mobx';
import Store from "./Store";
import $api from "../http";
import {UUID} from "crypto";

export default class TeamStore {
    private rootStore: Store;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }


    createTeam = async (data: any, hackathonId: string | undefined) => {
        try {
            const json = JSON.stringify({
                title: data.title,
                description: data.description || '',
                hackathonId: hackathonId
            });
            const response = await $api.post(`/teams`, json);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    getTeam = async (id: string) => {
        try {
            const response = await $api.get(`/teams/${id}`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    editTitle = async (values: any, id: string | undefined) => {
        try {
            const json = JSON.stringify({
                id: id,
                title: values.title,
            });
            const response = await $api.put(`/teams/change-title`, json);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    editDescription = async (values: any, id: string | undefined) => {
        try {
            const json = JSON.stringify({
                id: id,
                description: values.description,
            });
            const response = await $api.put(`/teams/change-description`, json);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }
    readyToCheck = async (id: string) => {
        try {
            const response = await $api.post(`/teams/${id}/ready`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    getInviteCode = async (id: string) => {
        try {
            const response = await $api.get<{ code: string }>(`/teams/${id}/code`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    regenerateInviteCode = async (id: string) => {
        try {
            const response = await $api.post(`/teams/${id}/regenerate-code`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    joinTeam = async (hackathonId: string, code: UUID): Promise<any> => {
        try {
            const json = JSON.stringify({
                hackathonId: hackathonId,
                code: code,
            });
            const response = await $api.post(`/teams/join`, json);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    };


    checkForHackathon = async (id: string) => {
        try {
            const response = await $api.get(`/teams/${id}/check-team`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }
    deleteMember = async (teamId: string, userId: string) => {
        try {
            const response = await $api.delete(`/teams/${teamId}/members/${userId}`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    getForHackathon = async (hackId: string) => {
        try {
            const response = await $api.get(`/teams/hackathon/${hackId}`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }

    }

    async getByIdForAdmin(teamId: string | undefined) {
        try {
            const response = await $api.get(`/teams/${teamId}/admin`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    scoreTeam = async (teamId: string | undefined, score: number) => {
        try {
            const json = JSON.stringify({
                teamId: teamId,
                score: score,
            });
            const response = await $api.post(`/teams/score`, json);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    acceptTeam = async (teamId: string | undefined) => {
        try {
            await $api.post(`/teams/${teamId}/accept`);
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    disqualifyTeam = async (teamId: string | undefined) => {
        try {
            await $api.post(`/teams/${teamId}/disqualify`);
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    unlockTeam = async (teamId: string | undefined) => {
        try {
            await $api.post(`/teams/${teamId}/unlock`);
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }
}