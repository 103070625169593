import React, {useContext, useEffect, useState} from 'react';
import cl from './AdminViewTeamPage.module.css'
import HeaderAdmin from "../../../components/templates/HeaderAdmin/HeaderAdmin";
import IndentBox from "../../../components/templates/IndentBox/IndentBox";
import {Button, Card, Form, Input, InputNumber, Modal, Tag, Tooltip} from "antd";
import {Link, useParams} from "react-router-dom";
import {AppContext} from "../../../index";
import {ITeamAdmin} from "../../../models/ITeamAdmin";
import DateTimeService from "../../../service/DateTimeService";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import cn from 'classnames';

const AdminViewTeamPage = () => {
    const {id, teamId} = useParams<{ id: string, teamId: string }>();
    const {store} = useContext(AppContext);
    const [team, setTeam] = useState<ITeamAdmin>();
    const [form] = Form.useForm();
    const [scoreForm] = Form.useForm();
    const [scoreModal, setScoreModal] = useState<boolean>(false);

    const [confirmAcceptModal, setConfirmAcceptModal] = useState<boolean>(false);
    const [confirmDisqualifyModal, setConfirmDisqualifyModal] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const team = await store.teams.getByIdForAdmin(teamId);
            setTeam(team);
            console.log(team)
        }
        fetchData();
    }, [
        store.teams,
        teamId
    ]);

    const updateTeam = async () => {
        const team = await store.teams.getByIdForAdmin(teamId);
        setTeam(team);
    }

    return (
        <>
            <Modal
                title="Оценить команду"
                open={scoreModal}
                okText={'Оценить'}
                cancelText={'Отмена'}
                onOk={() => {
                    scoreForm.validateFields()
                        .then(async values => {
                            const response = await store.teams.scoreTeam(teamId, values.score);
                            if (response) {
                                updateTeam();
                                //     setTeam(response);
                                setScoreModal(false);
                            }
                        })
                }}
                onCancel={() => {
                    setScoreModal(false);
                }}
            >
                <Form
                    form={scoreForm}
                    layout="vertical"
                    scrollToFirstError
                >
                    <Form.Item
                        label={`Оценка (от 1 до ${team?.hackathon?.maxScore})`}
                        name={'score'}
                        rules={[
                            {
                                required: true,
                                message: 'Введите оценку',
                            },
                        ]}
                    >
                        <InputNumber className={'w-full'} min={1} max={team?.hackathon?.maxScore}/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Подтвердите действие"
                open={confirmAcceptModal}
                okText={'Допустить'}
                cancelText={'Отмена'}
                onOk={async () => {
                    store.teams.acceptTeam(teamId)
                        .then(() => {
                            updateTeam();
                            setConfirmAcceptModal(false);
                        })
                }}
                onCancel={() => {
                    setConfirmAcceptModal(false);
                }}
            >
                <div className={'text-lg'}>
                    Вы действительно хотите допустить команду к участию?
                </div>
            </Modal>

            <Modal
                title="Подтвердите действие"
                open={confirmDisqualifyModal}
                okText={'Дисквалифицировать'}
                cancelText={'Отмена'}
                onOk={async () => {
                    store.teams.disqualifyTeam(teamId)
                        .then(() => {
                            updateTeam();
                            setConfirmDisqualifyModal(false);
                        })
                }}
                onCancel={() => {
                    setConfirmDisqualifyModal(false);
                }}
            >
                <div className={'text-lg'}>
                    Вы действительно хотите дисквалифицировать команду?
                </div>
            </Modal>

            <HeaderAdmin/>
            <IndentBox>
                {
                    team &&
                    <div className={cl.container}>
                        <h2>Команда '{team?.title}'</h2>
                        <Link to={`/admin/hackathons/${id}/teams`}>
                            <Card className={'mb-4'}>
                                <div className={'flex justify-between text-xl'}>
                                    <div>
                                        {team?.hackathon?.title}
                                    </div>
                                    <div>
                                        {DateTimeService.convertUnixTimestampToShortDateRange(team?.hackathon?.startDate, team?.hackathon?.endDate)}
                                    </div>
                                </div>
                            </Card>
                        </Link>

                        <div className={'mb-4 grid grid-cols-2 gap-4'}>
                            {
                                team?.status === 'READY_TO_CHECK' &&
                                <Button
                                    icon={<CheckOutlined/>}
                                    onClick={() => {
                                        setConfirmAcceptModal(true);
                                    }}
                                >
                                    Допустить до участия
                                </Button>
                            }
                            <Button
                                // className={team?.status === 'READY_TO_CHECK' ? 'hidden' : ''}
                                className={cn(
                                    team?.status !== 'READY_TO_CHECK' && 'col-span-2',
                                    team?.status === 'DISQUALIFIED' && cl.disabledButton,
                                )}
                                onClick={() => {
                                    setConfirmDisqualifyModal(true);
                                }}
                                icon={<CloseOutlined/>}
                            >
                                Дисквалифицировать
                            </Button>
                        </div>

                        <Card className={'mb-4'}>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={team}
                                className={'grid grid-cols-3 gap-10'}
                            >

                                <div>
                                    <Form.Item
                                        label="Капитан"
                                        name={'captain'}
                                    >
                                        <Tag>
                                            {team?.captain?.username}
                                        </Tag> - {team?.captain?.firstName} {team?.captain?.lastName}
                                    </Form.Item>
                                    <Form.Item
                                        label="Участники"
                                        name={'members'}
                                    >
                                        {
                                            team?.members?.map((member: any) => (
                                                <Tooltip title={member.firstName + ' ' + member.lastName}>
                                                    <Tag key={member.id} className={'cursor-pointer'}>
                                                        {member.username}
                                                    </Tag>
                                                </Tooltip>
                                            ))
                                        }
                                    </Form.Item>
                                    <Form.Item
                                        label="Стаутс"
                                        name={'status'}
                                    >
                                        {
                                            team?.status === 'NOT_READY' ?
                                                <Tag>Не готова</Tag> :
                                                team?.status === 'READY_TO_CHECK' ?
                                                    <Tag color="blue">Готова к проверке</Tag> :
                                                    team?.status === 'PARTICIPATING' ?
                                                        <Tag color="green">Участвует</Tag> :
                                                        team?.status === 'DISQUALIFIED' ?
                                                            <Tag color="red">Дисквалифицирована</Tag> :
                                                            team?.status === 'SOLVED' ?
                                                                <Tag color="geekblue">Решение отправлено</Tag> :
                                                                team?.status === 'CHECKED' ?
                                                                    <Tag color="purple">Проверено</Tag> :
                                                                    null
                                        }
                                    </Form.Item>
                                </div>
                                <div className={'col-span-2'}>
                                    <Form.Item
                                        label="Название"
                                        name={'title'}
                                    >
                                        <Input disabled/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Описание"
                                        name={'description'}
                                    >
                                        <Input.TextArea disabled/>
                                    </Form.Item>
                                </div>
                            </Form>
                        </Card>
                        <Card>
                            <div className={'flex justify-between'}>
                                <div>
                                    <h3 className={'text-lg'}>Решение</h3>
                                </div>
                                <div className={'cursor-pointer'}
                                     onClick={() => {
                                         setScoreModal(true);
                                     }}>
                                    {
                                        team?.answer &&
                                        team?.score ?
                                            <Tag color="green">
                                                Оценено на {team?.score} баллов
                                            </Tag> :
                                            <Tag>
                                                Оценить
                                            </Tag>
                                    }
                                </div>
                            </div>
                            <div className={'mt-5'}>
                                {team?.answer || <Tag>Решение не отправлено</Tag>}
                            </div>
                        </Card>
                    </div>
                }
            </IndentBox>
        </>
    );
};

export default AdminViewTeamPage;