import React from 'react';
import cl from './DeadlineBox.module.css';

interface DeadlineBoxProps {
    data: string;
    text: string;
}

const DeadlineBox:React.FC<DeadlineBoxProps> = ({data, text}) => {
    return (
        <div className={cl.box}>
            <h4 className={cl.data}>{data}</h4>
            <div className={cl.text}>{text}</div>
        </div>
    );
};

export default DeadlineBox;