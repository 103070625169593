import React, {useContext, useEffect, useState} from 'react';
import cl from './HackathonTeamsPage.module.css'
import {AppContext} from "../../../index";
import HeaderAdmin from "../../../components/templates/HeaderAdmin/HeaderAdmin";
import IndentBox from "../../../components/templates/IndentBox/IndentBox";
import {Link, useParams} from "react-router-dom";
import {IHackathon} from "../../../models/IHackathon";
import {ITeam} from "../../../models/ITeam";
import {Table, Tag, Tooltip} from "antd";
import {ITeamAdmin} from "../../../models/ITeamAdmin";

const HackathonTeamsPage = () => {
    const {id} = useParams<{ id: string }>();
    const {store} = useContext(AppContext)

    const [hackathon, setHackathon] = useState<IHackathon>();
    const [teams, setTeams] = useState<ITeamAdmin[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const hackathon = await store.hackathons.getByIdForAdmin(id as string);
            setHackathon(hackathon);
            const teams = await store.teams.getForHackathon(id as string);
            setTeams(teams);
        }
        fetchData();
    }, [id, store.hackathons, store.teams])


    return (
        <>
            <HeaderAdmin/>
            <IndentBox>
                <div className={cl.container}>
                    <h2>Команды хакатона</h2>

                    <Table
                        dataSource={teams}
                        pagination={false}
                        columns={[
                            {
                                title: 'Название',
                                dataIndex: 'title',
                                key: 'title',
                            }, {
                                title: 'Описание',
                                dataIndex: 'description',
                                key: 'description',
                            },
                            {
                                title: 'Баллы',
                                dataIndex: 'score',
                                key: 'score',
                                sorter: (a: any, b: any) => a.score - b.score,
                            },
                            {
                                title: 'Капитан',
                                dataIndex: 'captain',
                                key: 'captain',
                                render: (captain: any) => (
                                    <Tooltip title={captain?.firstName + ' ' + captain?.lastName}>
                                        <Link to={`/admin/users/${captain.id}`}>
                                            <Tag>
                                                {captain.username}
                                            </Tag>
                                        </Link>
                                    </Tooltip>
                                )
                            }, {
                                title: 'Статус',
                                dataIndex: 'status',
                                key: 'status',
                                onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
                                sorter: (a: any, b: any) => a.status.length - b.status.length,
                                render: (status: string) => (
                                    <>
                                        {
                                            status === 'NOT_READY' ?
                                                <Tag>Не готова</Tag> :
                                                status === 'READY_TO_CHECK' ?
                                                    <Tag color="blue">Готова к проверке</Tag> :
                                                    status === 'PARTICIPATING' ?
                                                        <Tag color="green">Участвует</Tag> :
                                                        status === 'DISQUALIFIED' ?
                                                            <Tag color="red">Дисквалифицирована</Tag> :
                                                            status === 'SOLVED' ?
                                                                <Tag color="geekblue">Решение отправлено</Tag> :
                                                                status === 'CHECKED' ?
                                                                    <Tag color="purple">Проверено</Tag> :
                                                                    null
                                        }
                                    </>

                                )
                            },
                            {
                                title: 'Участники',
                                dataIndex: 'members',
                                key: 'members',
                                render: (members: any[]) => (
                                    <div className={'flex flex-wrap w-52'}>
                                        {

                                            members.map(member => (
                                                <div className={'flex flex-wrap'}>
                                                    <Tooltip title={member.firstName + ' ' + member.lastName}>
                                                        <Link to={`/admin/users/${member.id}`}>
                                                            <Tag>
                                                                {member.username}
                                                            </Tag>
                                                        </Link>
                                                    </Tooltip>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            },
                            {
                                title: 'Действия',
                                dataIndex: 'id',
                                key: 'id',
                                render: (id: string) => (
                                    <div className={'flex flex-wrap'}>
                                        <Link to={`/admin/hackathons/${hackathon?.id}/teams/${id}`}>
                                            <Tag>
                                                Подробнее
                                            </Tag>
                                        </Link>
                                    </div>
                                )
                            }
                        ]}
                    />


                    {/*{*/
                    }
                    {/*    teams.map(team => (*/
                    }
                    {/*        <div key={team.id} className={cl.team}>*/
                    }
                    {/*            <h3>{team?.title}</h3>*/
                    }
                    {/*            <div className={cl.members}>*/
                    }
                    {/*                {*/
                    }
                    {/*                    team.members.map(member => (*/
                    }
                    {/*                        <div key={member.id} className={cl.member}>*/
                    }
                    {/*                            <div className={cl.member__name}>{member.username}</div>*/
                    }
                    {/*                            <div className={cl.member__email}>{member.email}</div>*/
                    }
                    {/*                        </div>*/
                    }
                    {/*                    ))*/
                    }
                    {/*                }*/
                    }
                    {/*            </div>*/
                    }
                    {/*        </div>*/
                    }
                    {/*    ))*/
                    }
                    {/*}*/
                    }

                </div>


            </IndentBox>
        </>
    )
        ;
};

export default HackathonTeamsPage;