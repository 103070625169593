import React, {useCallback, useContext, useEffect, useState} from 'react';
import cl from './Task.module.css';
import Header from "../../components/templates/Header/Header";
import IndentBox from "../../components/templates/IndentBox/IndentBox";
import Btn from "../../components/ui/Btn/Btn";
import ButtonHackathon from "../../components/ui/ButtonHackathon/ButtonHackathon";
import Footer from "../../components/templates/Footer/Footer";
import {useParams} from "react-router-dom";
import {AppContext} from "../../index";
import {IHackathon} from "../../models/IHackathon";
import {Statistic} from "antd";
import DateTimeService from "../../service/DateTimeService";
import TaskBlock from "../../components/task/TaskBlock/TaskBlock";

const {Countdown} = Statistic;


const Task = () => {
    const {id} = useParams<{ id: string }>();
    const {store} = useContext(AppContext);
    const [hackathon, setHackathon] = useState<IHackathon>();
    const [trigger, setTrigger] = useState<boolean>(true);

    const [checkInterval, setCheckInterval] = useState<any>(null);

    const [startDate, setSolvingDate] = useState<any>();
    const setUpStartDate = useCallback(() => {
        setSolvingDate({
            days: DateTimeService.convertBackDateToDate(hackathon?.endSolvingDate as number)?.getHours(),
            hours: DateTimeService.convertBackDateToDate(hackathon?.endSolvingDate as number)?.getHours(),
            minutes: DateTimeService.convertBackDateToDate(hackathon?.endSolvingDate as number)?.getMinutes(),
            seconds: DateTimeService.convertBackDateToDate(hackathon?.endSolvingDate as number)?.getSeconds()
        })
    }, [hackathon?.endSolvingDate]);


    useEffect(() => {
        const fetchData = async () => {
            const response = await store.hackathons.getHackathon(id as string);
            if (response)
                setHackathon(response as IHackathon);
        }
        fetchData();
        setUpStartDate();
        // Запускаем интервал проверки


    }, [
        id,
        store.hackathons,
        setHackathon,
        setUpStartDate
    ])


    const getDiffDays = (date: Date) => {
        const now = Date.now();
        const diff = date.getTime() - now;
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        return diffDays ? diffDays - 1 : 0;
    }

    const getDiffHours = (date: Date) => {
        const now = Date.now();
        const diff = date.getTime() - now;
        const diffHours = Math.ceil(diff / (1000 * 3600));
        return diffHours ? diffHours - 1 : 0;
    }

    const getDiffMinutes = (date: Date) => {
        const now = Date.now();
        const diff = date.getTime() - now;
        const diffMinutes = Math.ceil(diff / (1000 * 60));
        console.log(diffMinutes)
        return diffMinutes >= 0 ? diffMinutes - 1 : 0;
    }

    const getDiffSeconds = (date: Date) => {
        const now = Date.now();
        const diff = date.getTime() - now;
        const diffSeconds = Math.ceil(diff / (1000));

        return diffSeconds ? 0 : -diffSeconds - 1;
    }

    const handleTrigger = () => {
        setTrigger(false);
        setTimeout(() => {
            setTrigger(true);
        }, 2)
    }

    return (
        <>
            <Header/>
            <IndentBox>
                <div className={cl.preview}>
                    <h1 className={cl.title}>
                        {
                            hackathon?.title
                        }

                        {/*{*/}
                        {/*    startDate &&*/}
                        {/*    <span className={cl.date}>*/}
                        {/*        {startDate.days} дней {startDate.hours} часов {startDate.minutes} минут {startDate.seconds} секунд*/}
                        {/*    </span>*/}
                        {/*}*/}
                    </h1>
                    {
                        hackathon && trigger &&
                        <div className={cl.timerBox}>
                            <div className={cl.timer}>
                                <div className={cl.time}>
                                    <Countdown className={cl.timeNumber} value={hackathon?.startDate * 1000}
                                               format='D'
                                               onFinish={() => {
                                                   setTrigger(false);
                                                   // спим
                                                   setTimeout(() => {
                                                       setTrigger(true);
                                                   }, 2)
                                               }}
                                    />
                                    <div className={cl.timeText}>дн</div>
                                    <Countdown className={cl.timeNumber}
                                               value={
                                                   hackathon?.startDate * 1000
                                                   - getDiffDays(DateTimeService.convertBackDateToDate(hackathon?.startDate as number) as Date) * 1000 * 3600 * 24}
                                               format={'HH'}
                                               onFinish={() => {
                                                   setTrigger(false);
                                                   // спим
                                                   setTimeout(() => {
                                                       setTrigger(true);
                                                   }, 2)
                                               }}
                                    />

                                    <div className={cl.timeText}>ч</div>
                                    <Countdown className={cl.timeNumber}
                                               value={
                                                   hackathon?.startDate * 1000
                                                   - getDiffHours(DateTimeService.convertBackDateToDate(hackathon?.startDate as number) as Date) * 1000 * 3600}

                                               format={'mm'}
                                               onFinish={() => {
                                                   setTrigger(false);
                                                   // спим
                                                   setTimeout(() => {
                                                       setTrigger(true);
                                                   }, 2)
                                               }}
                                    />
                                    <div className={cl.timeText}>мин</div>
                                    <Countdown className={cl.timeNumber}
                                               value={
                                                   getDiffMinutes(DateTimeService.convertBackDateToDate(hackathon?.startDate as number) as Date) ?
                                                       hackathon?.startDate * 1000
                                                       - getDiffMinutes(DateTimeService.convertBackDateToDate(hackathon?.startDate as number) as Date) * 1000 * 60
                                                       : Date.now()
                                               }
                                               format={'ss'}
                                               onFinish={() => {
                                                   setTrigger(false);
                                                   // спим
                                                   setTimeout(() => {
                                                       setTrigger(true);
                                                   }, 2)
                                               }}
                                    />
                                    <div className={cl.timeText}>сек</div>
                                </div>
                                <div className={cl.timeText}>до открытия задания</div>
                            </div>
                            <div className={cl.timer}>
                                <div className={cl.time}>
                                    <Countdown className={cl.timeNumber} value={hackathon?.endSolvingDate * 1000}
                                               format='D'
                                               onFinish={() => {
                                                   setTrigger(false);
                                                   // спим
                                                   setTimeout(() => {
                                                       setTrigger(true);
                                                   }, 2)
                                               }}/>
                                    <div className={cl.timeText}>дн</div>
                                    <Countdown className={cl.timeNumber}
                                               value={
                                                   hackathon?.endSolvingDate * 1000
                                                   - getDiffDays(DateTimeService.convertBackDateToDate(hackathon?.endSolvingDate as number) as Date) * 1000 * 3600 * 24}
                                               format={'HH'}
                                               onFinish={() => {
                                                   setTrigger(false);
                                                   // спим
                                                   setTimeout(() => {
                                                       setTrigger(true);
                                                   }, 2)
                                               }}
                                    />
                                    <div className={cl.timeText}>ч</div>
                                    <Countdown className={cl.timeNumber}
                                               value={
                                                   hackathon?.endSolvingDate * 1000
                                                   - getDiffHours(DateTimeService.convertBackDateToDate(hackathon?.endSolvingDate as number) as Date) * 1000 * 3600}

                                               format={'mm'}
                                               onFinish={() => {
                                                   setTrigger(false);
                                                   // спим
                                                   setTimeout(() => {
                                                       setTrigger(true);
                                                   }, 2)
                                               }}
                                    />
                                    <div className={cl.timeText}>мин</div>
                                    <Countdown className={cl.timeNumber}
                                               value={
                                                   getDiffMinutes(DateTimeService.convertBackDateToDate(hackathon?.endSolvingDate as number) as Date) ?
                                                       hackathon?.endSolvingDate * 1000
                                                       // hackathon?.endSolvingDate * 1000
                                                       - getDiffMinutes(DateTimeService.convertBackDateToDate(hackathon?.endSolvingDate as number) as Date) * 1000 * 60
                                                       : 0
                                               }
                                               format={'ss'}
                                               onFinish={() => {
                                                   setTrigger(false);
                                                   // спим
                                                   setTimeout(() => {
                                                       setTrigger(true);
                                                   }, 2)
                                               }}
                                    />
                                    <div className={cl.timeText}>сек</div>
                                </div>
                                <div className={cl.timeText}>до защиты проектов</div>
                            </div>
                        </div>
                    }
                    <ButtonHackathon buttonText={"Перейти к команде"} link={"/team"}/>
                    <span className={cl.backCircle}/>
                    <span className={cl.backCircle2}/>
                    <span className={cl.backCircle3}/>
                    <span className={cl.backCircle4}/>
                </div>

                {
                    hackathon && trigger &&
                    // Проверяем что сейчас startDate уже наступил
                    hackathon.startDate * 1000 < Date.now() ?
                        <TaskBlock hackId={id as string}/> :
                        <div className={cl.textBox}>
                            <div className={cl.titleBox}>
                                <div className={cl.circle}/>
                                <h2>Задание скоро появится</h2>
                            </div>
                        </div>
                }
                {/*<div className={cl.textBox}>*/}
                {/*    <div className={cl.titleBox}>*/}
                {/*        <div className={cl.circle}/>*/}
                {/*        <h2>Какая задача?</h2>*/}
                {/*    </div>*/}

                {/*    <p>Разработайте веб-платформу, объединяющую инновационные технологии и креативный дизайн, чтобы*/}
                {/*        эффективно представить продукты и услуги в области информационных технологий. Сайт должен быть*/}
                {/*        не только функциональным, но и привлекательным для целевой аудитории, включая технологических*/}
                {/*        профессионалов, стартапы и потенциальных клиентов.</p>*/}
                {/*    <h4 className={cl.textTitle}>Требования:</h4>*/}
                {/*    <ul>*/}
                {/*        <li>Инновационный Дизайн: Разработайте современный и креативный дизайн, который отражает*/}
                {/*            динамизм и передовые технологии в сфере IT.*/}
                {/*        </li>*/}
                {/*        <li>Функциональность: Включите веб-страницы, обеспечивающие удобное взаимодействие с*/}
                {/*            пользователями, а также функционал для демонстрации продуктов и услуг, например,*/}
                {/*            видео-презентации, кейс-стади и т.д.*/}
                {/*        </li>*/}
                {/*        <li> Мобильная Адаптация: Обеспечьте полноценную адаптацию сайта под мобильные устройства, чтобы*/}
                {/*            обеспечить удобство использования на различных платформах.*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            Интеграция Технологий: Внедрите современные технологии, такие как искусственный интеллект,*/}
                {/*            блокчейн и другие, для повышения функциональности и уникальности сайта.*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            Безопасность: Обеспечьте высокий уровень безопасности для защиты данных пользователей и*/}
                {/*            обеспечения надежности веб-платформы.*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            Команда и Коллаборация: Покажите сплоченность и сотрудничество в команде, предоставив*/}
                {/*            описание ролей каждого участника и основные этапы разработки проекта.*/}
                {/*        </li>*/}
                {/*    </ul>*/}

                {/*    <h4 className={cl.textTitle}>Критерии оценки:</h4>*/}
                {/*    <ul>*/}
                {/*        <li>Инновационность и качество дизайна.</li>*/}
                {/*        <li> Функциональность и удобство использования.</li>*/}
                {/*        <li>Эффективность интеграции технологий.</li>*/}
                {/*        <li>Степень соответствия теме и требованиям.</li>*/}
                {/*        <li>Качество презентации и демонстрации.</li>*/}
                {/*    </ul>*/}
                {/*    <p>Дополнительные Инструкции: Участники хакатона должны представить свой проект в формате*/}
                {/*        презентации, включающей в себя демонстрацию веб-платформы, кода и краткого описания принятых*/}
                {/*        решений. Учитывайте ограниченное время для презентации (не более 10 минут).</p>*/}
                {/*</div>*/}

            </IndentBox>

            <Footer/>
        </>
    )
        ;
};

export default Task;