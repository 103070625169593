import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom"
import Home from "./pages/Home/Home";
import Register from "./pages/auth/Register/Register";
import Login from "./pages/auth/Login/Login";
import EmailConfirmation from "./pages/auth/EmailConfirmation/EmailConfirmation";
import Hackathons from "./pages/Hackathons/Hackathons";
import UserAccount from "./pages/UserAccount/UserAccount";
import Hackathon from "./pages/Hackathon/Hackathon";
import CreateTeam from "./pages/CreateTeam/CreateTeam";
import Team from "./pages/Team/Team";
import Task from "./pages/Task/Task";
import AdminHackathonsTables from "./pages/admin/AdminHackathonsTables/AdminHackathonsTables";
import JoinTeam from "./components/utils/JoinTeam";
import IsAuth from "./components/utils/IsAuth";
import IsAdmin from "./components/utils/IsAdmin";
import CreateEditHackathon from "./pages/admin/CreateEditHackathon/CreateEditHackathon";
import AdminUsersTable from "./pages/admin/AdminUsersTable/AdminUsersTable";
import HackathonTeamsPage from "./pages/admin/HackathonTeamsPage/HackathonTeamsPage";
import AdminViewTeamPage from "./pages/admin/AdminViewTeamPage/AdminViewTeamPage";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/email-confirmation" element={<EmailConfirmation/>}/>
            <Route path="/hackathons" element={<Hackathons/>}/>
            <Route path="/hackathons/:id" element={<Hackathon/>}/>

            {/*     Только для авторизованных пользователей   */}
            <Route element={<IsAuth/>}>
                <Route path="/account" element={<UserAccount/>}/>
                <Route path="/create-team/:id" element={<CreateTeam/>}/>
                <Route path="/join-team/:id/:code" element={<JoinTeam/>}/>
                <Route path="/teams/:id" element={<Team/>}/>
                <Route path="/hackathons/:id/task" element={<Task/>}/>

                {/*     Только для администраторов   */}
                <Route path="/admin" element={<IsAdmin/>}>
                    <Route path="/admin/hackathons" element={<AdminHackathonsTables/>}/>
                    <Route path="/admin/users" element={<AdminUsersTable/>}/>
                    <Route path="/admin/hackathons/:id" element={<CreateEditHackathon/>}/>
                    <Route path="/admin/hackathons/create" element={<CreateEditHackathon/>}/>
                    <Route path="/admin/hackathons/:id/teams" element={<HackathonTeamsPage/>}/>
                    <Route path="/admin/hackathons/:id/teams/:teamId" element={<AdminViewTeamPage/>}/>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
