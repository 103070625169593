import React, {useContext, useState} from 'react';
import cl from './UserInfo.module.css';

import ava from '../../media/images/ava.jpg';
import {Link} from "react-router-dom";
import TitleLine from "../TitleLine/TitleLine";
import {AppContext} from "../../index";
import {Alert, Button, Form, Input, Modal, Tag} from "antd";
import {observer} from "mobx-react";
import {EditOutlined} from "@ant-design/icons";

const UserInfo = () => {
    const {store} = useContext(AppContext)
    const [editUsernameModal, setEditUsernameModal] = useState(false)
    const [editAboutModal, setEditAboutModal] = useState(false)
    const [editPasswordModal, setEditPasswordModal] = useState(false)

    const [editUsernameForm] = Form.useForm()
    const [editAboutForm] = Form.useForm()
    const [editPasswordForm] = Form.useForm()

    const [user, setUser] = useState(store.user)

    React.useEffect(() => {
        const fetch = async () => {
            const data = await store.users.getUser()
            setUser(data as any)
        }
        fetch()
    }, [store, setUser])

    const updateData = async () => {
        setUser(await store.users.getUser() as any)
    }

    return (
        <div className={cl.infoBox}>
            <Modal
                open={editUsernameModal}
                title="Изменить имя пользователя"
                okText="Изменить"
                cancelText="Отмена"
                onCancel={() => {
                    setEditUsernameModal(false)
                    editUsernameForm.resetFields()
                }}
                onOk={() => {
                    editUsernameForm
                        .validateFields()
                        .then(async (values) => {
                            editUsernameForm.resetFields();
                            setEditUsernameModal(false);
                            await store.users.editUsername(values.username)
                        })
                }}
            >
                <Form
                    form={editUsernameForm}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        username: user?.username
                    }}
                >
                    <Alert className={'mb-2'} type={'error'} message={'Важно'}
                           description={'После изменения имени пользователя вам придется заново авторизоваться'}/>
                    <Form.Item
                        name="username"
                        label="Имя пользователя"
                        rules={[
                            {
                                required: true,
                                message: 'Введите имя пользователя',
                            },
                            {
                                min: 3,
                                message: 'Минимальная длина имени пользователя - 3 символа',
                            },
                            {
                                max: 20,
                                message: 'Максимальная длина имени пользователя - 20 символов',
                            },
                            {
                                pattern: /^[a-zA-Z0-9]+$/,
                                message: 'Имя пользователя может содержать только латинские буквы и цифры',
                            }
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>


            <Modal
                open={editAboutModal}
                title="Изменить поле обо мне"
                okText="Изменить"
                cancelText="Отмена"
                onCancel={() => {
                    setEditAboutModal(false)
                    editAboutForm.resetFields()
                }}
                onOk={() => {
                    editAboutForm
                        .validateFields()
                        .then(async (values) => {
                            // editAboutForm.resetFields();
                            setEditAboutModal(false);
                            await store.users.editAbout(values.about)
                            updateData()
                        })
                }}
            >
                <Form
                    form={editAboutForm}
                    layout="vertical"
                    name="form_in_modal_2"
                    initialValues={{
                        about: user?.about
                    }}
                >
                    <Form.Item
                        name="about"
                        label="Обо мне"
                        rules={[
                            {
                                max: 1000,
                                message: 'Максимальная длина поля обо мне - 1000 символов',
                            }
                        ]}
                    >
                        <Input.TextArea
                            rows={8}
                            showCount
                            maxLength={1000}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                open={editPasswordModal}
                title="Изменить пароль"
                okText="Изменить"
                cancelText="Отмена"
                onCancel={() => {
                    setEditPasswordModal(false)
                    editPasswordForm.resetFields()
                }}
                onOk={() => {
                    editPasswordForm
                        .validateFields()
                        .then(async (values) => {
                            editPasswordForm.resetFields();
                            // editAboutForm.resetFields();
                            setEditPasswordModal(false);
                            await store.users.changePassword(values)
                        })
                }}
            >
                <Form
                    form={editPasswordForm}
                    layout="vertical"
                    name="form_in_modal_3"
                    initialValues={{
                        about: user?.about
                    }}
                >
                    <Form.Item label={'Старый пароль'} name={'oldPassword'}
                               rules={[
                                   {
                                       required: true,
                                       message: 'Введите пароль',
                                   },
                                   {
                                       min: 8,
                                       message: 'Минимальная длина 8 символов'
                                   },
                                   {
                                       max: 50,
                                       message: 'Максимальная длина 50 символов'
                                   }
                               ]}
                               hasFeedback
                    >
                        <Input.Password className={'w-full'}/>
                    </Form.Item>
                    <Form.Item label={'Новый пароль'} name={'newPassword'}
                               rules={[
                                   {
                                       required: true,
                                       message: 'Введите пароль',
                                   },
                                   {
                                       min: 8,
                                       message: 'Минимальная длина 8 символов'
                                   },
                                   {
                                       max: 50,
                                       message: 'Максимальная длина 50 символов'
                                   }
                               ]}
                               hasFeedback
                    >
                        <Input.Password className={'w-full'}/>
                    </Form.Item>
                    <Form.Item
                        label={'Повторите новый пароль'} name={'newPasswordRepeat'}
                        dependencies={['newPassword']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Введите пароль',
                            }, {
                                min: 8,
                                message: 'Минимальная длина 8 символов'
                            },
                            {
                                max: 50,
                                message: 'Максимальная длина 50 символов'
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Пароли не совпадают'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password className={'w-full'}/>
                    </Form.Item>
                </Form>
            </Modal>

            <div className={cl.leftBlock}>
                <div className={cl.title}>Мой профиль</div>
                <img src={ava} alt={"ava"} className={cl.ava}/>
                <Button>Загрузить фото</Button>

                <Button className={cl.dangerBtn} onClick={store.users.logoutWithRedirect}>Выйти из аккаунта</Button>
            </div>
            <div className={cl.userInfo}>
                <div className={cl.nameBox}>
                    <div className={cl.name}>
                        {store.user?.username}
                    </div>
                    <EditOutlined onClick={() => setEditUsernameModal(true)} className={cl.edit}/>
                </div>
                <div className={cl.about}>
                    {user?.lastName + " " + user?.firstName}
                </div>
                {/*<div className={cl.about}>20 лет</div>*/}
                <div className={cl.aboutEditBox}>
                    <div className={cl.aboutBox}>
                        <span className={cl.about}>О&nbsp;себе: </span>
                        {user?.about}
                    </div>
                    <EditOutlined className={cl.edit} onClick={() => setEditAboutModal(true)}/>
                </div>
                <button className={cl.btn}
                        onClick={() => setEditPasswordModal(true)}
                >Изменить пароль
                </button>
            </div>
            <div className={cl.hackathonsInfo}>
                <TitleLine title={"Мои победы"}/>
                <div>
                    <span className={cl.about}>Всего хакатонов: </span>
                    {user?.teams?.length}
                </div>
                {/*<div>*/}
                {/*    <span className={cl.about}>Выигрышей: </span>*/}
                {/*    *засекречено**/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <span className={cl.about}>Был капитаном: </span>*/}
                {/*    {user?.teamsAsCaptainCount}*/}
                {/*</div>*/}
                <div>
                    <span className={cl.about}>Мои команды: </span>
                    {user?.teams?.map((team: any) => {
                        return (
                            <Link to={`/teams/${team.id}`}>
                                <Tag color="magenta">{team.title}</Tag>
                            </Link>
                        )
                    })}
                    {/*Garbage Collectors, Garbage Collectors, Garbage Collectors, Garbage Collectors, Garbage Collectors,*/}
                    {/*Garbage Collectors, Garbage Collectors, Garbage Collectors, Garbage Collectors, Garbage Collectors,*/}
                    {/*Garbage Collectors, Garbage Collectors, Garbage Collectors*/}
                </div>
            </div>
        </div>
    );
};

export default observer(UserInfo);