import React from 'react';
import cl from './CardsBox.module.css';

interface CardsBoxProps {
    children: React.ReactNode;
}

const CardsBox:React.FC<CardsBoxProps> = ({children}) => {
    return (
        <div className={cl.cardsBox}>
            {children}
        </div>
    );
};

export default CardsBox;