import React from 'react';
import cl from "./Home.module.css"

import Header from "../../components/templates/Header/Header";
import InviteBox from "../../components/InviteBox/InviteBox";

import timelineLogo1 from "../../media/images/timelineLogo1.png";
import timelineLogo2 from "../../media/images/timelineLogo2.png";
import timelineLogo3 from "../../media/images/timelineLogo3.png";
import timelineLogo4 from "../../media/images/timelineLogo4.png";
import preview from "../../media/images/preview.svg";
import backImg from "../../media/images/backImg.png";
import backImg2 from "../../media/images/backImg2.png";
import backImg3 from "../../media/images/backImg3.png";
import backImg5 from "../../media/images/backImg5.png";
import backImg6 from "../../media/images/backImg6.png";
import backImg8 from "../../media/images/backImg8.png";
import backImg10 from "../../media/images/backImg10.png";
import backImg11 from "../../media/images/backImg11.png";
import robot from "../../media/images/writeRobot.png";
import InviteCard from "../../components/InviteCard/InviteCard";
import TimelineStage from "../../components/TimelineStage/TimelineStage";
import {Link} from "react-router-dom";
import PrimaryButtonHeight from "../../components/ui/PrimaryButtonHeight/PrimaryButtonHeight";
import Footer from "../../components/templates/Footer/Footer";

const Home = () => {
    return (
        <>
            <img src={preview} alt={"preview"} className={cl.previewImg}/>
            <Header/>
            <div className={cl.preview}>
                <h1 className={cl.title}>mbc hackathons</h1>
                <div className={cl.description}>А ты готов сразиться?</div>
            </div>

            <div className={cl.inviteBox}>
                <InviteBox/>
            </div>

            <div className={cl.box}>

                <TimelineStage circleColor="#DB00FF" timelineImage={timelineLogo1} h2="выбери свой хакатон"
                               className={cl.line1}
                               p="Наш сайт предлагает широкий спектр программистских хакатонов, от
                            интенсивных соревнований по
                            алгоритмам и структурам данных до инновационных проектов, где ты сможешь воплотить свои
                            самые
                            смелые идеи. Покажи свою экспертность в различных языках программирования!"/>

                <div className={cl.cardBox}>
                    <InviteCard link={"/hackathons#programming"} title={"Программирование"}
                                description={"Наш хакатон по программированию - это арена для тех, кто готов бросить вызов сложным задачам и показать свой творческий потенциал. Присоединяйтесь к нам, чтобы соревноваться, учиться и вдохновляться в мире кода и алгоритмов."}/>
                    <InviteCard link={"/hackathons#creation"} title={"Творчество"}
                                description={"Ваше творчество может стать центральным элементом потрясающего веб-дизайна и графических искусств. Примите участие и вдохновляйтесь, создавая уникальные визуальные мастерские произведения, которые подчеркнут ваш талант и креативность."}/>
                    <InviteCard link={"/hackathons#general"} title={"Общие знания"}
                                description={"У нас есть вызов для всех, кто горит жаждой знаний! Проверьте свою эрудицию в различных областях, от математики и физики до литературы и истории. Покажите, что вы настоящий знаток и готовы раскрывать новые горизонты знаний."}/>
                    <img src={backImg3} alt={"backImg"} className={cl.backImg4}/>
                    <img src={backImg2} alt={"backImg"} className={cl.backImg2}/>
                    <img src={backImg3} alt={"backImg"} className={cl.backImg3}/>
                    <img src={backImg} alt={"backImg"} className={cl.backImg}/>
                    <img src={backImg5} alt={"backImg"} className={cl.backImg5}/>
                </div>

                <img src={backImg6} alt={"backImg"} className={cl.backImg6}/>
                <img src={backImg3} alt={"backImg"} className={cl.backImg7}/>
                <img src={backImg8} alt={"backImg"} className={cl.backImg8}/>
                <img src={backImg3} alt={"backImg"} className={cl.backImg9}/>
                <img src={backImg10} alt={"backImg"} className={cl.backImg10}/>
                <img src={robot} alt={"backImg"} className={cl.robot}/>

                <div className={cl.line2}/>

                <TimelineStage circleColor="#1000CA" timelineImage={timelineLogo2} h2="зарегистрируйся"
                               className={cl.line3}
                               p="Заполните простую регистрационную форму, и вы сможете участвовать в захватывающих
                            соревнованиях, работать с талантливыми людьми и решать реальные проблемы. Не упустите шанс
                            на рост, соревнование и незабываемые впечатления. Зарегистрируйтесь сегодня и откройте для
                            себя новые возможности!"/>

                <TimelineStage circleColor="#DB00FF" timelineImage={timelineLogo3} h2="выполни задание"
                               className={cl.line1}
                               p="Мы приготовили увлекательные и вызывающие задачи, которые способны проверить и расширить ваши
                            знания и навыки. Независимо от вашего опыта, у нас есть место для всех: от начинающих до
                            опытных участников. Докажите, что вы способны на великое, выполнив наше захватывающее
                            задание!"/>

                <TimelineStage circleColor="#1000CA" timelineImage={timelineLogo4} h2="выигрывай !" className={cl.line3}
                               p="Отточите свои навыки, сотрудничайте с единомышленниками и докажите, что именно ваше решение
                            заслуживает первого места. Поднимите планку, стремитесь к победе и станьте одним из тех, кто
                            привносит инновации и революционные идеи в мир технологий. Вперед, к победе – ваше время
                            пришло!"/>

                <img src={backImg11} alt={"backImg"} className={cl.backImg11}/>

                <div className={cl.formBox} id="register">
                    <h3>Регистрация</h3>
                    <div className={cl.form}>
                        <input type="text" placeholder="Имя"/>
                        <input type="text" placeholder="Фамилия"/>
                        <input type="text" placeholder="Ник"/>
                        <input type="email" placeholder="Email"/>
                    </div>
                    <div className={cl.btnBox}>
                        <PrimaryButtonHeight buttonText={"Зарегистрироваться"} link={"/register"}/>
                    </div>
                    <div className={cl.linkBox}>
                        <Link to={"/login"} className={cl.link}>Уже есть аккаунт?</Link>
                        <Link to={"/"} className={cl.link}>Войти через MBC аккаунт</Link>
                    </div>
                </div>

                <img src={backImg6} alt={"backImg"} className={cl.backImg12}/>
            </div>

            <Footer/>
        </>
    );
};

export default Home;