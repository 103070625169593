import {makeAutoObservable} from 'mobx';
import UserStore from './UserStore';
import HackathonStore from './HackathonStore';
import TeamStore from "./TeamStore";
import {message, notification} from "antd";
import $api from "../http";

export default class Store {
    hackathons = new HackathonStore(this);
    users = new UserStore(this);
    teams = new TeamStore(this);

    constructor() {
        makeAutoObservable(this, {
                hackathons: false,
                users: false,
                teams: false
            },
            {
                deep: true
            });
        this.checkAuth();
    }

    isAuthState = false;
    userState = null as any;


    checkAuth = () => {
        const token = localStorage.getItem('token');
        if (token) {
            this.loadUser(token);
        }
    }

    loadUser = (token: string) => {
        const check = async () => {
            try {
                await $api.get('/users/check');
            } catch (e) {
                console.log(23)
                this.isAuth = false;
                this.user = null;
                localStorage.removeItem('token');

                message.error('Ваша сессия истекла. Пожалуйста, авторизуйтесь заново');
            }
        }
        const {id, email, role} = JSON.parse(atob(token.split('.')[1]));
        const username = JSON.parse(atob(token.split('.')[1])).sub;
        this.user = {id, username, email, role};
        this.isAuth = true;
        check();

    }

    get isAuth() {
        return this.isAuthState;
    }

    set isAuth(value) {
        this.isAuthState = value;
    }

    get user() {
        return this.userState;
    }

    set user(value) {
        this.userState = value;
    }

    errorHandler = (e: any) => {
        // switch-case by http code
        const msg = 'Ошибка сервера';
        if (e.response) {
            switch (e.response?.status) {
                case 400 || 401:
                    if (e.response.data?.title === 'Constraint Violation') {
                        notification.error({
                            message: 'Ошибка валидации данных',
                            description: e.response.data?.violations?.map((v: any) => <p>{v.message}</p>),
                            duration: 3,
                        });
                    } else {
                        notification.error({
                            message: e.response.data?.title,
                            description: e.response.data?.detail,
                            duration: 3,
                        });
                    }

                    // notification.error({
                    //     message: e.response.data?.title,
                    //     description: e.response.data?.detail,
                    // });
                    break;
                // case 401:
                //     message.error('Необходимо авторизоваться');
                //     break;
                case 403:
                    message.error('Недостаточно прав');
                    break;
                case 404:
                    message.error('Не найдено');
                    break;
                default:
                    notification.error({
                        message: e.response.data.title,
                        description: e.response.data.detail,
                        duration: 3,
                    });
            }
        } else {
            message.error(msg);
        }
    }

    isAdmin(): boolean {
        return this.user?.role === 'ROLE_ADMIN';
    }
}
