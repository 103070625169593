import React from 'react';
import cl from './CircleTimeline.module.css';

interface CircleTimelineProps {
    circleColor: string;
    timelineImage: string;
}

const CircleTimeline: React.FC<CircleTimelineProps> = ({circleColor, timelineImage}) => {
    const circleStyle = {
        backgroundColor: circleColor,
    };

    return (
        <div>
            <img src={timelineImage} className={cl.timelineImg} alt={"choice"}/>
            <div className={cl.circle} style={circleStyle}/>
        </div>
    );
};

export default CircleTimeline;
