import {makeAutoObservable} from 'mobx';
import Store from "./Store";
import $api from "../http";
import {message} from "antd";
import {IUser} from "../models/IUser";

export default class UserStore {
    private rootStore: Store;
    currentUser = null;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    clearCurrentUser() {
        this.currentUser = null;
    }

    register = async (data: any) => {
        this.logout();
        try {
            delete data.passwordConfirm;
            const json = JSON.stringify(data);
            const response = await $api.post('/auth/signup', json);
            localStorage.setItem('token', response.data.token);
            this.rootStore.checkAuth();
            message.info('Вы успешно зарегистрировались!');
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    login = async (data: any) => {
        try {
            const response = await $api.post('/auth/signin', data);
            localStorage.setItem('token', response.data.token);
            this.rootStore.checkAuth();
            message.info('Вы успешно вошли!');
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    logout = () => {
        try {
            localStorage.removeItem('token');
            this.rootStore.isAuth = false;
        } catch (e) {
            console.log(e);
        }
    }

    logoutWithRedirect = () => {
        this.logout();
        window.location.href = '/';
    }

    getUser = async () => {
        try {
            const response = await $api.get<any>(`/users/profile`);
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    async editUsername(username: string) {
        try {
            await $api.put('/users/username', {username});
            message.info('Имя пользователя успешно изменено!');
            this.logoutWithRedirect();
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
        return false;
    }

    editAbout = async (about: string) => {
        try {
            await $api.put('/users/about', {about});
            message.info('Информация о вас успешно изменена!');
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }

    changePassword = async (data: any) => {
        try {
            const json = JSON.stringify({
                oldPassword: data.oldPassword,
                newPassword: data.newPassword
            });
            await $api.put('/users/password', json);
            message.info('Пароль успешно изменен!');
        } catch (e) {
            this.rootStore.errorHandler(e);
        }

    }
    getAll = async () => {
        try {
            const response = await $api.get<IUser[]>('/users');
            return response.data;
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }
    delete = async (id: string) => {
        try {
            await $api.delete(`/users/${id}`);
            message.info('Пользователь успешно удален!');
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }
    ban = async (id: string) => {
        try {
            await $api.put(`/users/${id}/ban`);
            message.info('Пользователь успешно забанен!');
        } catch (e) {
            this.rootStore.errorHandler(e);
        }
    }
}