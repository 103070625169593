import React from 'react';
import cl from "./InviteBox.module.css";
import PrimaryButton from "../ui/PrimaryButton/PrimaryButton";
import robot1 from "../../media/images/inviteRobot.png";

const InviteBox = () => {
    return (
        <div className={cl.glass}>
            <p className={cl.text}>Добро пожаловать на сайт умственного сражения! Здесь ты найдешь арену для проверки своих знаний и навыков. Прими вызов хакатона и покажи свою экспертность в различных областях. Готов ли ты сразиться с лучшими? Присоединяйся к нам и открой дверь в мир соревнований, обучения и возможностей для профессионального роста.</p>
            <PrimaryButton text={"Принять вызов!"} link={"#register"}/>
            <img src={robot1} className={cl.robot} alt={"robot"}/>
        </div>
    );
};

export default InviteBox;