import React, {useContext, useEffect, useState} from 'react';
import cl from './CreateTeam.module.css';
import Header from "../../components/templates/Header/Header";
import IndentBox from "../../components/templates/IndentBox/IndentBox";
import TitleLine from "../../components/TitleLine/TitleLine";
import PrimaryButtonHeight from "../../components/ui/PrimaryButtonHeight/PrimaryButtonHeight";
import Footer from "../../components/templates/Footer/Footer";
import classNames from "classnames";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../index";
import {IHackathon} from "../../models/IHackathon";
import {Button, Form, Input, Tag} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";

const CreateTeam = () => {
    const {id} = useParams<{ id: string }>()
    const {store} = useContext(AppContext);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const [team, setTeam] = useState<any>(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const response = await store.teams.checkForHackathon(id as string);
            if (response)
                setTeam(response as IHackathon);
        }
        fetchData();
    }, [
        id, setTeam, store.teams
    ]);

    return (
        <>
            <Header/>
            <IndentBox>
                {
                    team &&
                    <Link to={`/teams/${team?.id}`}>
                        <div className={cl.existingTeam}>
                            <div className={cl.existingTeamTitle}>
                                Вы уже состоите в команде '<span className={'font-bold'}>{team.title}</span>'
                            </div>
                            <ArrowRightOutlined className={'pr-5 font-bold'}/>
                        </div>
                    </Link>
                }
                <div className={cl.box}>
                    <Form
                        form={form}
                        scrollToFirstError
                        onFinish={async values => {
                            const response = await store.teams.createTeam(values, id)
                            if (response) {
                                navigate(`/teams/${response.id}`)
                            }
                        }}
                        layout={"vertical"}
                        className={cl.teamBox}>
                        <TitleLine title={"Создание команды"}/>
                        <Form.Item
                            name={"title"}
                            label={"Название команды"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста введите название команды',
                                },
                                {
                                    min: 5,
                                    message: 'Название должно быть не менее 5 символов',
                                },
                                {
                                    max: 255,
                                    message: 'Название должно быть не более 255 символов',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name={"description"}
                            label={"Описание команды"}
                            rules={[
                                {
                                    max: 255,
                                    message: 'Описание должно быть не более 255 символов',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item>
                            <Button htmlType={"submit"}>
                                Создать команду
                            </Button>
                        </Form.Item>
                    </Form>

                    <div className={cl.orTitleBox}>
                        <div>или</div>
                    </div>

                    <Form
                        form={form2}
                        scrollToFirstError
                        onFinish={async values => {
                            const response = await store.teams.joinTeam(id as string, values.code)
                            if (response) {
                                navigate(`/teams/${response.id}`)
                            }
                        }}
                        layout={"vertical"}
                        className={cl.teamBox}>
                        <TitleLine title={"Вступление в команду"}/>
                        <div className={cl.teamDesc}>
                            Для того, чтобы вступить в уже существующую команду, введите код группы или вступите по
                            ссылке-приглашению, которую отправит вам капитан команды
                        </div>
                        <Form.Item
                            name={"code"}
                            label={"Код группы"}
                            rules={[
                                {
                                    max: 255,
                                    message: 'Описание должно быть не более 255 символов',
                                },
                                {
                                    required: true,
                                    message: 'Пожалуйста введите код группы',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item>
                            <Button htmlType={"submit"}>
                                Вступить в команду
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </IndentBox>
            <Footer/>
        </>
    );
};

export default CreateTeam;