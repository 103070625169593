import React, {useContext, useEffect} from 'react';
import cl from './TaskBlock.module.css'
import {AppContext} from "../../../index";
import {Button, Form, Tag} from "antd";
import Btn from "../../ui/Btn/Btn";
import TextArea from "antd/es/input/TextArea";

const TaskBlock = ({hackId}: { hackId: string }) => {
        const {store} = useContext(AppContext);
        const [task, setTask] = React.useState<any>(null)
        const [form] = Form.useForm();


        useEffect(() => {
            const getTask = async () => {
                const response = await store.hackathons.getTask(hackId);
                if (response) {
                    setTask(response);
                }
            }
            getTask();
        }, [
            store.hackathons,
            hackId,
            setTask
        ]);

        return (
            <>

                <div className={cl.textBox}>
                    <div className={cl.titleBox}>
                        <div className={cl.circle}/>
                        <h2>Какая задача?</h2>
                    </div>

                    {task ? task.fullTask : <Tag>Скорое появится</Tag>}

                    {/*<p>Разработайте веб-платформу, объединяющую инновационные технологии и креативный дизайн, чтобы*/}
                    {/*    эффективно представить продукты и услуги в области информационных технологий. Сайт должен быть*/}
                    {/*    не только функциональным, но и привлекательным для целевой аудитории, включая технологических*/}
                    {/*    профессионалов, стартапы и потенциальных клиентов.</p>*/}
                    {/*<h4 className={cl.textTitle}>Требования:</h4>*/}
                    {/*<ul>*/}
                    {/*    <li>Инновационный Дизайн: Разработайте современный и креативный дизайн, который отражает*/}
                    {/*        динамизм и передовые технологии в сфере IT.*/}
                    {/*    </li>*/}
                    {/*    <li>Функциональность: Включите веб-страницы, обеспечивающие удобное взаимодействие с*/}
                    {/*        пользователями, а также функционал для демонстрации продуктов и услуг, например,*/}
                    {/*        видео-презентации, кейс-стади и т.д.*/}
                    {/*    </li>*/}
                    {/*    <li> Мобильная Адаптация: Обеспечьте полноценную адаптацию сайта под мобильные устройства, чтобы*/}
                    {/*        обеспечить удобство использования на различных платформах.*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*        Интеграция Технологий: Внедрите современные технологии, такие как искусственный интеллект,*/}
                    {/*        блокчейн и другие, для повышения функциональности и уникальности сайта.*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*        Безопасность: Обеспечьте высокий уровень безопасности для защиты данных пользователей и*/}
                    {/*        обеспечения надежности веб-платформы.*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*        Команда и Коллаборация: Покажите сплоченность и сотрудничество в команде, предоставив*/}
                    {/*        описание ролей каждого участника и основные этапы разработки проекта.*/}
                    {/*    </li>*/}
                    {/*</ul>*/}

                    {/*<h4 className={cl.textTitle}>Критерии оценки:</h4>*/}
                    {/*<ul>*/}
                    {/*    <li>Инновационность и качество дизайна.</li>*/}
                    {/*    <li> Функциональность и удобство использования.</li>*/}
                    {/*    <li>Эффективность интеграции технологий.</li>*/}
                    {/*    <li>Степень соответствия теме и требованиям.</li>*/}
                    {/*    <li>Качество презентации и демонстрации.</li>*/}
                    {/*</ul>*/}
                    {/*<p>Дополнительные Инструкции: Участники хакатона должны представить свой проект в формате*/}
                    {/*    презентации, включающей в себя демонстрацию веб-платформы, кода и краткого описания принятых*/}
                    {/*    решений. Учитывайте ограниченное время для презентации (не более 10 минут).</p>*/}
                </div>

                {
                    task &&
                    task.canSendSolution ?
                        <Form
                            form={form}
                            scrollToFirstError
                            onFinish={async values => {
                                const response = await store.hackathons.sendTask(hackId, values)
                                if (response) {
                                    setTask(response)
                                }
                            }}
                            layout={"vertical"}
                        >
                            <div className={cl.teamInputBox}>
                                <div className={cl.inputTitle}>Ответ на задание</div>

                                <Form.Item
                                    name={"solution"}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Пожалуйста введите ответ',
                                        },
                                        {
                                            min: 5,
                                            message: 'Ответ должен быть не менее 5 символов',
                                        },
                                        {
                                            max: 5000,
                                            message: 'Ответ должен быть не более 5000 символов',
                                        }
                                    ]}
                                >
                                    <TextArea className={cl.input} rows={8} maxLength={5000} showCount/>
                                </Form.Item>


                                {/*<TextArea className={cl.input}/>*/}
                            </div>
                            <div className={cl.btnBox}>
                                <Form.Item>
                                    <Button htmlType={"submit"} style={{
                                        backgroundColor: '#136B31',
                                        color: '#fff',
                                    }}>
                                        Отправить решение
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                        :
                        task?.isSolved ?
                            <div className={cl.btnBox}>
                                <Tag color={"green"}>Задача решена</Tag>
                            </div>
                            :
                            <div className={cl.btnBox}>
                                <Tag color={"red"}>
                                    У вас нет доступа к отправке задачи
                                </Tag>
                            </div>
                }
            </>
        );
    }
;

export default TaskBlock;