import React, {useContext} from 'react';
import {observer} from "mobx-react";
import {AppContext} from "../../index";
import {Navigate, Outlet} from "react-router-dom";

const IsAdmin = ({children}: any) => {
    const {store} = useContext(AppContext);
    if (!store.isAdmin()) {
        return <Navigate to={'/403'} replace/>;
    }
    return children ? children : <Outlet/>;
};

export default observer(IsAdmin);