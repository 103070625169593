import React from 'react';
import cl from './PartnerInfo.module.css';

import star from '../../media/images/star.png';

interface PartnerInfoProps {
    avatar: string;
    nikName: string;
    name: string | number;
    description: string;
    isCaptain?: boolean;
    seeCaptain: boolean;
    id?: string;
    del?: () => void;
}

const PartnerInfo: React.FC<PartnerInfoProps> = ({nikName, name, description, avatar, isCaptain, seeCaptain, del}) => {
    return (
        <div className={cl.box}>
            <div className={cl.mainBox}>
                <img src={avatar} alt={"ava"} className={cl.ava}/>
                <div className={cl.textBox}>
                    <div className={cl.mainInfoBox}>
                        <div className={cl.mainInfo}>
                            {nikName}, {name}
                        </div>
                        {
                            isCaptain &&
                            <img src={star} alt={"star"} className={cl.star}/>
                        }
                    </div>
                    <div className={cl.descText}>
                        <span className={cl.descTitle}>О&nbsp;себе: </span>
                        {description}
                    </div>
                </div>
            </div>
            {
                !isCaptain && seeCaptain &&
                <button
                    onClick={async () => {
                        del && del()
                    }}
                    className={cl.btn}>Удалить участника из команды</button>
            }
        </div>
    );
};

export default PartnerInfo;