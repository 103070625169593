import React, {useContext} from 'react';
import Header from "../../components/templates/Header/Header";
import TitleLine from "../../components/TitleLine/TitleLine";
import IndentBox from "../../components/templates/IndentBox/IndentBox";
import CardsBox from "../../components/templates/CardsBox/CardsBox";
import CardHackathon from "../../components/CardHackathon/CardHackathon";
import hackBack from "../../media/images/hackBack.png";
import hackBack2 from "../../media/images/hackBack2.png";
import hackBack3 from "../../media/images/hackBack3.png";
import Footer from "../../components/templates/Footer/Footer";
import UserInfo from "../../components/UserInfo/UserInfo";
import {observer} from "mobx-react";
import {AppContext} from "../../index";

const UserAccount = () => {
    const {store} = useContext(AppContext)

    return (
        <>
            <Header/>
            <IndentBox>
                <UserInfo/>
                <TitleLine title={'Мои хакатоны'}/>
                <CardsBox>
                    <CardHackathon
                        img={hackBack}
                        title={'Хакатон по алгоритмическим задачам “Algoritmic Showdown" '}
                        type={"Онлайн"}
                        data={"до 20 ноября"}
                        to={'/hackathon'}/>

                    <CardHackathon
                        img={hackBack2}
                        title={'Хакатон CodeSprint: Быстрый старт в программировании '}
                        type={"Онлайн"}
                        data={"до 20 ноября"}
                        to={'/hackathon'}/>

                    <CardHackathon
                        img={hackBack3}
                        title={'Хакатон CodeCraft: Реальный мир в виртуальных данных '}
                        type={"Онлайн"}
                        data={"до 20 ноября"}
                        to={'/hackathon'}/>

                    <CardHackathon
                        img={hackBack}
                        title={'Хакатон по алгоритмическим задачам “Algoritmic Showdown" '}
                        type={"Онлайн"}
                        data={"до 20 ноября"}
                        to={'/hackathon'}/>
                </CardsBox>
            </IndentBox>
            <Footer/>
        </>
    );
};

export default observer(UserAccount);