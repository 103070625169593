import React, {useContext} from 'react';
import cl from './Header.module.css';

import logo from '../../../media/images/logo.png';
import {Link} from "react-router-dom";
import {Button} from "antd";
import {AppContext} from "../../../index";
import {observer} from "mobx-react";

const Header = () => {
    const {store} = useContext(AppContext)
    return (
        <div className={cl.headerBox}>
            <div className={cl.header}>
                <div className={cl.logoBox}>
                    <img src={logo} className={cl.logo} alt={"logo"}/>
                    <div className={cl.logoTitle}>MBC</div>
                </div>
                <div className={cl.pageTitleBox}>
                    <Link to={'/'}>Главная</Link>
                    <Link to={'/hackathons'}>Хакатоны</Link>
                    <Link to={'/'}>Партнерство</Link>
                    {
                        store.isAdmin() &&
                        <Link to={'/admin/hackathons'}>
                            Админпанель
                        </Link>
                    }
                </div>

                {
                    store.isAuth ?
                        <Link to={'/account'}>
                            <Button>
                                Профиль
                            </Button>
                        </Link>
                        :
                        <Link to={'/login'}>
                            <Button>
                                Войти
                            </Button>
                        </Link>
                }
            </div>
        </div>
    );
};

export default observer(Header);