import React, {useContext} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../index";
import {UUID} from "crypto";

const JoinTeam = () => {

    const navigate = useNavigate();

    const {id, code} = useParams<{
        id: string,
        code: UUID
    }>()

    const {store} = useContext(AppContext);

    const response: any = store.teams.joinTeam(id as string, code as UUID);

    if (response) {
        navigate(`/teams/${response.id}`)
    } else {
        navigate(`/create-team/${id}`)
    }

    return null;
};

export default JoinTeam;