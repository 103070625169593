import React, {useContext, useEffect, useState} from 'react';
import HeaderAdmin from "../../../components/templates/HeaderAdmin/HeaderAdmin";
import IndentBox from "../../../components/templates/IndentBox/IndentBox";
import {AppContext} from "../../../index";
import {IUser} from "../../../models/IUser";
import cl from './AdminUsersTable.module.css'
import {Modal, Table, Tag, Tooltip} from "antd";
import {EyeOutlined, ToolOutlined} from "@ant-design/icons";

const AdminUsersTable = () => {
    const {store} = useContext(AppContext)
    const [users, setUsers] = useState<[IUser]>()
    const [selectedUser, setSelectedUser] = useState<IUser>()
    const [deleteUserModal, setDeleteUserModal] = useState(false)


    useEffect(() => {
        const fetchData = async () => {
            const response = await store.users.getAll()
            if (response)
                setUsers(response as [IUser])
        }
        fetchData()
    }, [
        store.users,
        setUsers
    ])

    const updateData = async () => {
        const response = await store.users.getAll()
        if (response)
            setUsers(response as [IUser])
    }

    return (
        <>
            <Modal
                title="Блоировка пользователя"
                open={deleteUserModal}
                okText={'Заблокировать'}
                cancelText={'Отмена'}
                cancelButtonProps={{type: 'default'}}
                onOk={() => {
                    store.users.ban(selectedUser?.id as string)
                    updateData();
                }}
                onCancel={() => {
                    setDeleteUserModal(false)
                }}
            >
                <p>Вы действительно хотите заблокировать <Tag
                    color={'blue'}>{selectedUser?.username}</Tag>?</p>
                <p>Пользователь не сможет войти в свой аккаунт и участвовать в хакатонах</p>
            </Modal>

            <HeaderAdmin/>
            <IndentBox>
                <div className={cl.container}>
                    <div className={cl.containerHeader}>
                        <h2>Пользователи</h2>
                    </div>
                    <Table
                        dataSource={users}
                        rowKey={user => user.id}
                        pagination={{position: ['none']}}
                        columns={[
                            {
                                title: 'ID',
                                dataIndex: 'id',
                                key: 'id',
                                render: (id: string) => (
                                    <Tooltip title={<div className={'break-keep'}>{id}</div>}>
                                        <Tag>
                                            {id.slice(0, 5)}
                                        </Tag>
                                    </Tooltip>
                                )
                            },
                            {
                                title: 'Никнейм',
                                dataIndex: 'username',
                                key: 'username',
                            },
                            {
                                title: 'Фамилия',
                                dataIndex: 'lastName',
                                key: 'lastName'
                            },
                            {
                                title: 'Имя',
                                dataIndex: 'firstName',
                                key: 'firstName',

                            },
                            {
                                title: 'Роль',
                                dataIndex: 'role',
                                key: 'role',
                                render: (role: string) => (
                                    {
                                        'ROLE_USER': <Tag color={'blue'}>Пользователь</Tag>,
                                        'ROLE_ADMIN': <Tag color={'red'}>Администратор</Tag>
                                    }[role]
                                )
                            },
                            {
                                title: 'Почта',
                                dataIndex: 'email',
                                key: 'email',
                                render: (email: string) => (
                                    <Tag>
                                        {email}
                                    </Tag>
                                )
                            },
                            {
                                title: 'Действия',
                                dataIndex: 'id',
                                key: 'id',
                                render: (id: string) => (
                                    <div className={cl.activeBox}>
                                        <EyeOutlined/>
                                        <ToolOutlined
                                            onClick={() => {
                                                setSelectedUser(users?.find(user => user.id === id) as IUser)
                                                setDeleteUserModal(true)
                                            }}
                                            className={'text-red-700'}/>
                                    </div>
                                )
                            }
                        ]}/>
                </div>
            </IndentBox>
        </>
    );
};

export default AdminUsersTable;