import React from 'react';
import cl from './HackathonProgram.module.css';

interface HackathonProgramProps {
    date:string;
    children: React.ReactNode;
}

const HackathonProgram:React.FC<HackathonProgramProps> = ({children, date}) => {
    return (
        <div className={cl.box}>
            <div className={cl.date}>{date}</div>
            <div className={cl.dateDescription}>
                {children}
            </div>
        </div>
    );
};

export default HackathonProgram;