import React from 'react';
import cl from './TitleLine.module.css';

interface HackathonsTypeProps{
    title: string;
}

const TitleLine:React.FC <HackathonsTypeProps> = ({title}) => {
    return (
        <div className={cl.box}>
            <span className={cl.dash}/>
            <p className={cl.text}>{title}</p>
            <span className={cl.dash}/>
        </div>
    );
};

export default TitleLine;