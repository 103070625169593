import React from 'react';
import { Link } from 'react-router-dom'; // Импорт Link из React Router
import cl from './CardHackathon.module.css';

interface CardHackathonProps {
    img: string;
    title: string;
    type: string;
    data: string;
    to: string;
}

const CardHackathon: React.FC<CardHackathonProps> = ({ img, title, type, data, to }) => {
    return (
        <Link to={to} className={cl.box}>
            <img src={img} alt="hackBack" className={cl.img}/>
            <div className={cl.textBox}>
                <p className={cl.title}>{title}</p>
                <p className={cl.type}>{type}</p>
                <div className={cl.registerBox}>
                    <p className={cl.register}>Регистрация:</p>
                    <p className={cl.data}>{data}</p>
                </div>
            </div>
        </Link>
    );
};

export default CardHackathon;
