import React, {useContext, useEffect} from 'react';
import cl from "./CreateEditHackathon.module.css"
import HeaderAdmin from "../../../components/templates/HeaderAdmin/HeaderAdmin";
import {AppContext} from "../../../index";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, DatePicker, Form, Input, InputNumber, Space, TimePicker} from "antd";
import IndentBox from "../../../components/templates/IndentBox/IndentBox";
import DateTimeService from "../../../service/DateTimeService";
import dayjs from 'dayjs';
import {CloseOutlined} from "@ant-design/icons";


const {RangePicker} = DatePicker;

const CreateEditHackathon = () => {
    const {id} = useParams<{ id: string }>();
    const {store} = useContext(AppContext);
    const [hackathonForm] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        const getHackathon = async () => {
            const hackathon = await store.hackathons.getByIdForAdmin(id as string);
            if (!hackathon) {
                return;
            }
            hackathonForm.setFieldsValue(
                {
                    title: hackathon.title,
                    description: hackathon.description,
                    shortTask: hackathon.shortTask,
                    fullTask: hackathon.fullTask,
                    maxTeamSize: hackathon.maxTeamSize,
                    maxScore: hackathon.maxScore,
                    ApplicationsDates: [dayjs(DateTimeService.convertBackDateToDate(hackathon.startApplicationsDate)),
                        dayjs(DateTimeService.convertBackDateToDate(hackathon.endApplicationsDate))],
                    hackathonDates: [dayjs(DateTimeService.convertBackDateToDate(hackathon.startDate)),
                        dayjs(DateTimeService.convertBackDateToDate(hackathon.endDate))],
                    endDate: dayjs(DateTimeService.convertBackDateToDate(hackathon.endDate)),
                    steps: hackathon.steps.map((step: any) => {
                        return {
                            date: dayjs(DateTimeService.convertBackDateToDate(step.date)),
                            events: step.events.map((event: any) => {
                                return {
                                    time: [dayjs(DateTimeService.convertBackTimeToDate(event.startTime)),
                                        dayjs(DateTimeService.convertBackTimeToDate(event.endTime))],
                                    title: event.title
                                }
                            })
                        }
                    })
                }
            );
        }
        if (id) {
            getHackathon();
        }
    }, [store, id, hackathonForm]);

    return (
        <>
            <HeaderAdmin/>
            <IndentBox>
                <div className={cl.container}>
                    <h2>Редактор хакатонов</h2>
                    <Card>
                        <Form
                            form={hackathonForm}
                            layout="vertical"
                            name="hackathonForm"
                            onFinish={async (values) => {
                                const data = {
                                    title: values.title,
                                    description: values.description,
                                    shortTask: values.shortTask,
                                    fullTask: values.fullTask,
                                    maxTeamSize: values.maxTeamSize,
                                    maxScore: values.maxScore,
                                    startApplicationsDate: DateTimeService.convertDateToUnixTimestamp(values.ApplicationsDates[0].toDate()),
                                    endApplicationsDate: DateTimeService.convertDateToUnixTimestamp(values.ApplicationsDates[1].toDate()),
                                    startDate: DateTimeService.convertDateToUnixTimestamp(values.hackathonDates[0].toDate()),
                                    endSolvingDate: DateTimeService.convertDateToUnixTimestamp(values.hackathonDates[1].toDate()),
                                    endDate: DateTimeService.convertDateToUnixTimestamp(values.endDate.toDate()),
                                    steps: values.steps.map((step: any) => {
                                        return {
                                            date: DateTimeService.convertDateToUnixTimestamp(step.date.toDate()),
                                            events: step.events.map((event: any) => {
                                                return {
                                                    startTime: DateTimeService.convertDateToTime(event.time[0].toDate()),
                                                    endTime: DateTimeService.convertDateToTime(event.time[1].toDate()),
                                                    title: event.title
                                                }
                                            })
                                        }
                                    })
                                }

                                const response = id ?
                                    await store.hackathons.update(id, data) :
                                    await store.hackathons.create(data);
                                if (response) {
                                    navigate('/admin/hackathons');
                                }
                            }}
                            initialValues={{
                                title: '',
                                description: '',
                                shortTask: '',
                                fullTask: '',
                                maxTeamSize: 4,
                                maxScore: 10,
                                ApplicationsDates: [],
                                hackathonDates: [],
                                endDate: null
                            }}
                        >
                            <Form.Item
                                label="Название"
                                name="title"
                                rules={[
                                    {required: true, message: 'Введите название хакатона'},
                                    {min: 5, message: 'Минимальная длина названия хакатона 5 символа'},
                                    {max: 255, message: 'Максимальная длина названия хакатона 255 символов'}
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Описание"
                                name="description"
                                rules={[
                                    {max: 500, message: 'Максимальная длина описания хакатона 500 символов'}
                                ]}
                            >
                                <Input.TextArea maxLength={500} showCount rows={8}/>
                            </Form.Item>


                            <Form.Item
                                label="Короткое задание"
                                name="shortTask"
                                rules={[
                                    {max: 500, message: 'Максимальная длинна короткого задания 500 символов'}
                                ]}
                            >
                                <Input.TextArea maxLength={500} showCount rows={8}/>
                            </Form.Item>

                            <Form.Item
                                label="Полное задание"
                                name="fullTask"
                                rules={[
                                    {required: true, message: 'Введите полное задание'},
                                    {max: 5000, message: 'Максимальная длинна полного задания 5000 символов'}
                                ]}
                            >
                                <Input.TextArea maxLength={5000} showCount rows={15}/>
                            </Form.Item>

                            <Form.Item
                                label="Максимальное число участников в команде"
                                name="maxTeamSize"
                                rules={[
                                    {required: true, message: 'Введите максимальное число участников в команде'},
                                ]}
                            >
                                <InputNumber max={100} min={1} className={'w-full'}/>
                            </Form.Item>

                            <Form.Item
                                label="Максимальное количество баллов, которое может получить команда"
                                name="maxScore"
                                rules={[
                                    {required: true, message: 'Введите кол-во баллов'},
                                ]}
                            >
                                <InputNumber max={1000} min={1} className={'w-full'}/>
                            </Form.Item>

                            <Form.Item
                                label="Начало и окончание приема заявок"
                                name="ApplicationsDates"
                                rules={[
                                    {required: true, message: 'Введите даты приема заявок'},
                                ]}
                            >
                                <RangePicker showTime format="YYYY-MM-DD HH:mm:ss"/>
                            </Form.Item>
                            <Form.Item
                                label="Открытие и закрытие хакатона (задания)"
                                name="hackathonDates"
                                rules={[
                                    {required: true, message: 'Введите даты хакатона'},
                                ]}
                            >
                                <RangePicker showTime format="YYYY-MM-DD HH:mm:ss"/>
                            </Form.Item>
                            <Form.Item
                                label="Открытие и закрытие хакатона (задания)"
                                name="endDate"
                                rules={[
                                    {required: true, message: 'Введите дату закрытия'},
                                ]}
                            >
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss"/>
                            </Form.Item>


                            <h3 className={'text-xl'}>Этапы и события</h3>
                            <Form.List name="steps">
                                {(fields, {add, remove}) => (
                                    <div style={{display: 'flex', rowGap: 16, flexDirection: 'column'}}>
                                        {fields.map((field) => (
                                            <Card
                                                size="small"
                                                title={`Этап - ${field.name + 1}`}
                                                key={field.key}
                                                extra={
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            remove(field.name);
                                                        }}
                                                    />
                                                }
                                            >
                                                <Form.Item label="Дата этапа" name={[field.name, 'date']}>
                                                    <DatePicker/>
                                                </Form.Item>
                                                <Form.Item label="События">
                                                    <Form.List name={[field.name, 'events']}>
                                                        {(events, subOpt) => (
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                rowGap: 16
                                                            }}>
                                                                {events.map((subField) => (
                                                                    <Space key={subField.key}>
                                                                        <Form.Item label={'Время'}
                                                                                   name={[subField.name, 'time']}>
                                                                            <TimePicker.RangePicker/>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label={'Название события'}
                                                                            name={[subField.name, 'title']}>
                                                                            <Input/>
                                                                        </Form.Item>
                                                                        <CloseOutlined
                                                                            onClick={() => {
                                                                                subOpt.remove(subField.name);
                                                                            }}
                                                                        />
                                                                    </Space>
                                                                ))}
                                                                <Button type="dashed" onClick={() => subOpt.add()}
                                                                        block>
                                                                    + Добавить событие
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </Form.List>
                                                </Form.Item>
                                            </Card>
                                        ))}
                                        <Button onClick={() => add()} block>
                                            + Добавить этап
                                        </Button>
                                    </div>

                                )}
                            </Form.List>

                            <Form.Item className={'mt-5'}>
                                <Button className={cl.btn} htmlType="submit">
                                    {
                                        id ? 'Редактировать' : 'Создать'
                                    }
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </IndentBox>
        </>
    );
};

export default CreateEditHackathon;