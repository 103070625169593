import React from 'react';
import cl from './HeaderAdmin.module.css';

import logo from '../../../media/images/logo.png';
import {Link} from "react-router-dom";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";

const HeaderAdmin = () => {
    return (
        <div className={cl.headerBox}>
            <div className={cl.header}>
                <div className={cl.logoBox}>
                    <img src={logo} className={cl.logo} alt={"logo"}/>
                    <div className={cl.logoTitle}>MBC</div>
                </div>
                <div className={cl.pageTitleBox}>
                    <Link to={'/'}>Главная</Link>
                    <Link to={'/admin/hackathons'}>Хакатоны</Link>
                    <Link to={'/admin/users'}>Пользователи</Link>
                </div>
                <PrimaryButton text={"Выйти"} link={"/login"}/>
            </div>
        </div>
    );
};

export default HeaderAdmin;